// import { FooterHomeSecond } from '../../components/FooterHome/FooterHomeSecond';
// import { SingleProduct } from '../../components/SingleProduct/SingleProduct';
// import { useLocation, useParams } from 'react-router-dom';
// import { RelatedProduct } from '../../components/RelatedProduct/RelatedProduct';
// import { TopBar } from '../../TopBar/TopBar';
// import { useEffect } from 'react';

// export const ProductDetails = () => {
//   const location = useLocation();
//   const { id } = useParams(); 
//     const item = location.state && location.state.item; 
//   // console.log("item:>>>>>>>>>>>>>", item);

//    useEffect(() => {
//      window.scrollTo(0, 0);
//    }, []);

//   return (
//     <div style={{ backgroundColor: "#F1F0F1" }}>
//       <TopBar />
//       <SingleProduct id={id} />
//       {item && <RelatedProduct item={item} />}
//       <FooterHomeSecond />
//     </div>
//   );
// }


import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FooterHomeSecond } from '../../components/FooterHome/FooterHomeSecond';
import { SingleProduct } from '../../components/SingleProduct/SingleProduct';
import { RelatedProduct } from '../../components/RelatedProduct/RelatedProduct';
import { TopBar } from '../../TopBar/TopBar';

export const ProductDetails = () => {
  const location = useLocation();
  const { id } = useParams(); 
  const [item, setItem] = useState(null); // State to hold the item data

  useEffect(() => {
    // Check if location state has item data
    if (location.state && location.state.item) {
      setItem(location.state.item); // Update item state with data from location state
    }
    window.scrollTo(0, 0);
  }, [location.state]); // Trigger useEffect when location state changes

  return (
    <div style={{ backgroundColor: "#F1F0F1" }}>
      <TopBar />
      <SingleProduct id={id} />
      {item && <RelatedProduct item={item} />}
      <FooterHomeSecond />
    </div>
  );
}
