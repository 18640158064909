import React, { useEffect } from "react";
import "./orderConfirmation.css";
import { TopPage } from "../TopPage/TopPage";
import { FooterHomeSecond } from "../FooterHome/FooterHomeSecond";
import { TopBar } from "../../TopBar/TopBar";
import gmilogo from "../../assests/gmi-logo.png";

export const OrderConfirmation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <TopBar />
      <div className="OrderContainer">
        <div>
          <img
            className="confirmImg"
            src="https://img.freepik.com/free-vector/male-mechanic-repairs-motorcycle-wheel-isolated-white-background-man-fixing-motorbike-repair-shop-garage-concept-motor-service-repair-maintenance-garage_575670-1889.jpg?w=740&t=st=1703053257~exp=1703053857~hmac=643de7a974be94ae22b20bbd07cad6edd2fe4815669d178c68360956998d8e06"
            alt="bikeBook"
          />
        </div>
        <div style={{ width: "90%", margin: "auto" }}>
          <p className="textOrder">
            Thank You, Order Received, Pickup boy will Arrived Soon
          </p>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
