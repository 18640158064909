import React, { useEffect } from 'react';
import { FooterHomeSecond } from '../../components/FooterHome/FooterHomeSecond';
import { TopBar } from '../../TopBar/TopBar';
import "./AboutPage.css"

export const AboutPage = () => {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <div style={{backgroundColor:"F1F0F1"}}>
        <TopBar/>
        <div className='aboutBox'>
        <p>AboutPage</p>
        </div>
        <FooterHomeSecond/>
    </div>
  );
}
