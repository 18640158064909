import React, { useEffect } from "react";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { TopPage } from "../../components/TopPage/TopPage";
import "./TipFact1.css";
import { TopBar } from "../../TopBar/TopBar";
import imgz from "../../assests/mechanic-doodle.jpg";

export const TipFact4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ backgroundColor: "F1F0F1" }}>
      <TopBar />
      <div className="tipsBox">
        <div
          style={{
            fontSize: 30,
            fontWeight: "bold",
            color: "blacks",
            textAlign: "center",
          }}
        >
          What happens if I don’t service my vehicle?
        </div>
        <div style={{ margin: "5% 0" }} className="tipText">
          If you are a vehicle owner, skipping out on routine maintenance can
          affect your vehicle’s performance, safety, and lifetime. According to
          Greasemonkey, a reputable vehicle servicing provider, the following
          can occur if you don’t maintain your vehicle:
        </div>
        <div style={{ margin: "5% 0" }}>
          <ol type="1" style={{ lineHeight: 1.5 }}>
            <li className="tipText">
              Reduced Performance: Your vehicle’s brakes, gears, and drivetrain
              can all suffer from regular wear and tear over time. If these
              parts aren’t constantly inspected, adjusted, and greased, their
              performance may deteriorate, which could lead to less effective
              braking, shifting issues, poor power transmission, and generally
              subpar performance.
            </li>
            <li className="tipText">
              Enhanced Accident Risk: Poorly maintained vehicles might endanger
              the rider’s safety as well as those of other road users. For
              instance, worn-out tyres or brake pads might make it harder for
              the vehicle to stop quickly, raising the possibility of accidents.
              Unexpected shifting problems brought on by broken gears or chains
              might result in loss of balance or falls. Regular vehicle
              maintenance reduces the likelihood of accidents and promotes safe
              riding by allowing any safety hazards to be identified and
              addressed early on.
            </li>
            <li className="tipText">
              Expensive Repairs: If routine vehicle maintenance is neglected,
              minor difficulties may develop into big concerns that necessitate
              expensive repairs or part replacements. For instance, if a
              worn-out chain is not replaced in a timely manner, it may create
              excessive wear on the powertrain and gears, necessitating
              additional costly repairs. Regular maintenance assists in
              identifying and resolving minor concerns early on, avoiding more
              expensive repairs later.
            </li>
            <li className="tipText">
              Reduced Lifespan of Vehicle Components: vehicles comprise a number
              of parts, each of which has a limited lifespan. Regular
              maintenance prolongs the life of these parts. Without regular
              upkeep, parts like bearings, pedals, bottom brackets, and others
              could prematurely wear out, requiring frequent replacements and
              costing more money.
            </li>
            <li className="tipText">
              Lower Resale Value: If you intend to sell or trade in your vehicle
              in the future, a well-kept vehicle with a record of its
              maintenance will often fetch a higher asking price than a
              neglected vehicle. Maintaining your vehicle in good shape and
              retaining its worth over time can be accomplished with routine
              servicing.
            </li>
          </ol>
        </div>
        <div className="tipText">
          To summarize, we at Greasemonkey believe frequent vehicle servicing is
          critical for safety, performance, longevity, cost-effectiveness, and
          warranty compliance. Don’t skip it if you want to keep your vehicle
          running smoothly and safely for years to come.
        </div>
        <div style={{ margin: "5% 0" }}>
          <div className="tipCardText">YOU MIGHT ALSO LIKE</div>
          <div className="tipmotoMainBox">
            <div className="tipMotoBox">
              <img className="tipImg" src={imgz} />
              <div className="tipCardText">
                The top 10 signs that your vehicle needs servicing.
              </div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
            <div className="tipMotoBox">
              <img className="tipImg" src={imgz} />
              <div className="tipCardText">
                What happens if I don’t service my vehicle?
              </div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
            <div className="tipMotoBox">
              <img className="tipImg" src={imgz} />
              <div className="tipCardText">
                The benefits of regular oil changes for your vehicle: why you
                shouldn’t delay
              </div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
          </div>
          <div
            style={{
              width: "40%",
              margin: "2% auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ margin: "2% 0" }} className="tipCardText">
              Leave a Reply
            </div>
            <textarea
              style={{
                border: "1px solid black",
                width: "100%",
                height: 100,
                margin: "5% 0",
              }}
            />
            <button style={{ width: "50%", backgroundColor: "#FFC701" }}>
              Post Comment
            </button>
          </div>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
