import React, { useEffect } from 'react';
import { FooterHomeSecond } from '../../components/FooterHome/FooterHomeSecond';
import { TopPage } from '../../components/TopPage/TopPage';
import "./TipFact1.css"
import { TopBar } from '../../TopBar/TopBar';
import imgz from "../../assests/mechanic-doodle.jpg";

export const TipFact3 = () => {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <div style={{ backgroundColor: "F1F0F1" }}>
      <TopBar />
      <div className="tipsBox">
        <div
          style={{
            fontSize: 30,
            fontWeight: "bold",
            color: "blacks",
            textAlign: "center",
          }}
        >
          The top 10 signs that your vehicle needs
        </div>
        <div
          style={{
            fontSize: 30,
            fontWeight: "bold",
            color: "blacks",
            textAlign: "center",
          }}
        >
          servicing.
        </div>
        <div style={{ margin: "5% 0" }} className="tipText">
          Greasemonkey recognizes the need for frequent servicing for your
          vehicle to keep it running smoothly and safely. Here are the top ten
          indicators that your vehicle needs servicing:
        </div>
        <div style={{ margin: "5% 0" }}>
          <ol type="1" style={{ lineHeight: 1.5 }}>
            <li className="tipText">
              Unusual Noises: If you hear peculiar noises originating from your
              vehicle, such as rattling, clunking, or squeaking, it could be an
              indication of a problem with the engine, exhaust, or other
              mechanical components that need to be serviced.
            </li>
            <li className="tipText">
              Inadequate Fuel Efficiency: If you observe a sudden decline in
              fuel efficiency or an increase in fuel consumption, it could be
              due to a clogged air filter, spark plug problems, or other
              fuel-related issues that require repair to restore ideal
              performance.
            </li>
            <li className="tipText">
              Trouble Starting: If your vehicle has trouble starting or takes
              longer to start than usual, it could be due to problems with the
              battery, ignition system, or fuel supply, which may necessitate
              servicing to diagnose and repair.
            </li>
            <li className="tipText">
              Decreased Power or Acceleration: If you notice a loss in power or
              sluggish acceleration, it could be a sign of an issue with the
              engine, fuel system, or gearbox that has to be serviced to restore
              appropriate performance.
            </li>
            <li className="tipText">
              Excessive Vibrations or Shaking: Excessive vibrations or shaking
              when riding could signal problems with the wheels, suspension, or
              other components that must be serviced to guarantee safe and
              smooth operation.
            </li>
            <li className="tipText">
              Brake Problems: If you observe any indicators of brake
              difficulties, such as squeaking, grinding, or decreased braking
              ability, you must have your brakes checked and repaired as soon as
              possible for your safety on the road.
            </li>
            <li className="tipText">
              Fluid Leaks: If you see any leaks of oil, coolant, or other fluids
              from your vehicle, it could indicate a problem with the engine,
              transmission, or other components that need to be serviced to
              prevent further damage.
            </li>
            <li className="tipText">
              Worn Tyres: If your tyres exhibit indications of severe wear, such
              as uneven tread wear or bald areas, they must be replaced or
              repaired to maintain proper traction and stability on the road.
            </li>
            <li className="tipText">
              Electrical Issues: If you have problems with your two vehicle’s
              lights, indicators, or other electrical components, this could
              signal a problem with the wiring, battery, or electrical system,
              which may require servicing to ensure proper operation.
            </li>
            <li className="tipText">
              Planned Maintenance: If your vehicle has reached the
              manufacturer’s recommended scheduled maintenance period, it must
              be serviced to avoid potential problems and keep it in top shape.
            </li>
          </ol>
        </div>
        <div className="tipText">
          Finally, as Greasemonkey, we stress the necessity of paying attention
          to these symptoms and having your vehicle serviced as soon as possible
          to solve any potential issues and ensure a safe and smooth operation
          on the road. Frequent servicing can help prevent serious problems,
          extend the life of your vehicle, and keep it performing at its best.
        </div>
        <div style={{ margin: "5% 0" }}>
          <div className="tipCardText">YOU MIGHT ALSO LIKE</div>
          <div className="tipmotoMainBox">
            <div className="tipMotoBox">
              <img className="tipImg" style={{ width: 300 }} src={imgz} />
              <div className="tipCardText">YES Talks</div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
            <div className="tipMotoBox">
              <img className="tipImg" src={imgz} />
              <div className="tipCardText">
                The impact of seasonal changes on your vehicle: how to prepare
                for it
              </div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
            <div className="tipMotoBox">
              <img className="tipImg" src={imgz} />
              <div className="tipCardText">
                Difference between local servicing centres and Greasemonkey’s
                authorised servicing centres
              </div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
          </div>
          <div
            style={{
              width: "40%",
              margin: "2% auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ margin: "2% 0" }} className="tipCardText">
              Leave a Reply
            </div>
            <textarea
              style={{
                border: "1px solid black",
                width: "100%",
                height: 100,
                margin: "5% 0",
              }}
            />
            <button style={{ width: "50%", backgroundColor: "#FFC701" }}>
              Post Comment
            </button>
          </div>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
}
