// import { AllRoutes } from './AllRoutes';
// import './App.css';

// function App() {
//   return (
//     <AllRoutes/>
//   );
// }

// export default App;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { AllRoutes } from "./AllRoutes";
import "./App.css";
import maintenancePage from "./assests/maintenancePage.png";

function App() {
  const [isMaintenance, setIsMaintenance] = useState(false);
  // console.log("isMaintenance:", isMaintenance);

  useEffect(() => {
    const checkMaintenanceStatus = async () => {
      try {
        const response = await axios.get(
          "https://gmi-server-1.onrender.com/maintenance/maintenancestatus"
        );

        const maintenanceTimeoutString =
          response.data.maintenance[0].maintenanceTimeout;

        // Parse the date string manually and create a Date object
        const maintenanceTimeout = new Date(maintenanceTimeoutString);

        // Extract year, month, and day components of maintenance timeout date
        const year = maintenanceTimeout.getUTCFullYear();
        const month = String(maintenanceTimeout.getUTCMonth() + 1).padStart(
          2,
          "0"
        ); // Months are zero-indexed
        const day = String(maintenanceTimeout.getUTCDate()).padStart(2, "0");

        // Concatenate the components to format the maintenance timeout date
        const formattedMaintenanceTimeout = `${year}${month}${day}`;
        // console.log(
        //   "formattedMaintenanceTimeout:",
        //   formattedMaintenanceTimeout
        // );

        // Get the current date in the same format
        const currentDate = new Date();
        const currentYear = currentDate.getUTCFullYear();
        const currentMonth = String(currentDate.getUTCMonth() + 1).padStart(
          2,
          "0"
        );
        const currentDay = String(currentDate.getUTCDate()).padStart(2, "0");
        const formattedCurrentDate = `${currentYear}${currentMonth}${currentDay}`;

        // Compare formatted current date with formatted maintenance timeout date
        if (
          parseInt(formattedCurrentDate, 10) >=
          parseInt(formattedMaintenanceTimeout, 10)
        ) {
          // Show maintenance page
          setIsMaintenance(true);
        } else {
          // Continue with your logic here...
        }
      } catch (error) {
        console.error("Error fetching maintenance status:", error.message);
      }
    };

    checkMaintenanceStatus();
  }, []);

  /// maintenance page
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      {isMaintenance ? (
        <div
          className="fullscreen-image"
          style={{
            width: windowDimensions.width,
            height: windowDimensions.height,
          }}
        >
          <h1
            style={{
              textAlign: "center",
              border: "1px solid transparent",
            }}
          >
            Maintenance Page
          </h1>
          <img
            src={maintenancePage}
            alt="maintenancePage"
            className="fullscreen-image__img"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      ) : (
        <AllRoutes />
      )}
    </div>
  );
}

export default App;
