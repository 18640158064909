


import React, { useState } from "react";
import "./connectwithus.css";
import { TopBar } from "../../TopBar/TopBar";
import { FooterHomeOne } from "../FooterHome/FooterHomeOne";

// Functional component for the business form
export const EarnWithUs = () => {
  // State variables to store form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    occuption: "",
  });

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add your business logic here for form submission
    console.log("Form data submitted:", formData);
    // Clear the form after submission
    setFormData({
      name: "",
      email: "",
      mobile: "",
      occuption: "",
    });
  };

  return (
    <div>
      <TopBar />
      <div className="connectBox">
        <h2 className="connectheading">Earn With Us</h2>
        <form className="connectform" onSubmit={handleSubmit}>
          <label className="connectlabel" htmlFor="name">
            Name:
          </label>
          <input
            type="text"
            className="connectinput"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />

          <label className="connectlabel" htmlFor="email">
            Email:
          </label>
          <input
            type="email"
            className="connectinput"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />

          <label className="connectlabel" htmlFor="mobile">
            Mobile No.:
          </label>
          <input
            type="mobile"
            className="connectinput"
            name="mobile"
            value={formData.mobile}
            onChange={handleInputChange}
            required
          />

          <label className="connectlabel" htmlFor="occuption">
            Occuption:
          </label>
          <input
            type="occuption"
            className="connectinput"
            name="occuption"
            value={formData.occuption}
            onChange={handleInputChange}
            required
          />

          <button className="connectbutton" type="submit">
            Submit
          </button>
        </form>
      </div>
      <FooterHomeOne />
    </div>
  );
};
 