import React, { useEffect } from 'react';
import { FooterHomeSecond } from '../../components/FooterHome/FooterHomeSecond';
import { TopPage } from '../../components/TopPage/TopPage';
import "./TipFact1.css"
import { TopBar } from '../../TopBar/TopBar';
import imgz from "../../assests/mechanic-doodle.jpg";

export const TipFact2 = () => {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <div style={{ backgroundColor: "F1F0F1" }}>
      <TopBar />
      <div className="tipsBox">
        <div
          style={{
            fontSize: 30,
            fontWeight: "bold",
            color: "blacks",
            textAlign: "center",
          }}
        >
          The impact of seasonal changes on your
        </div>
        <div
          style={{
            fontSize: 30,
            fontWeight: "bold",
            color: "blacks",
            textAlign: "center",
          }}
        >
          vehicle: how to prepare for it
        </div>
        <div style={{ margin: "5% 0" }} className="tipText">
          We at Greasemonkey recognize that seasonal changes can substantially
          impact the performance and safety of your vehicle. Here are some
          suggestions for preparing your vehicle for seasonal changes:
        </div>
        <div style={{ margin: "5% 0" }}>
          <ol type="1" style={{ lineHeight: 1.5 }}>
            <li className="tipText">
              Check Fluid Levels: The fluid levels in your vehicle, such as
              engine oil, coolant, and brake fluid, might change with the
              seasons. Check the fluid levels and top them off to guarantee
              appropriate operation and performance in changing weather
              conditions.
            </li>
            <li className="tipText">
              Tyre Maintenance: Seasonal variations can affect tyre performance.
              Regularly check your tyre pressure and adjust it according to the
              manufacturer’s instructions. Inspect your tyres for signs of wear,
              damage, or cracks. Depending on the environment in your area,
              consider converting to appropriate seasonal tyres, such as winter
              or summer tyres.
            </li>
            <li className="tipText">
              Battery Check: Cold temperatures can cause your vehicle’s battery
              to function poorly. Check the battery’s charge and condition
              before the winter season begins. Charge or replace the battery to
              guarantee reliable starting and electrical performance in cooler
              conditions..
            </li>
            <li className="tipText">
              Cooling System: In hotter weather, your vehicle’s cooling system
              must be in good working order to avoid overheating. Examine the
              coolant level, inspect the hoses for leaks or cracks, and ensure
              the radiator is clean and debris-free. If it’s been a while since
              the last service, consider flushing and refilling the coolant.
            </li>
            <li className="tipText">
              Lighting and Visibility: Seasonal changes can impact road
              visibility. Check that all your lights, including the wheels
              headlights, taillights, turn signals, and brake lights, are
              operational. If additional lighting, such as fog lights, is
              required for greater visibility during foggy or rainy seasons,
              consider installing it.
            </li>
            <li className="tipText">
              Chain and Sprocket Maintenance: The chain and sprocket are key
              components of the drivetrain on your vehicle. Inspect and lube the
              chain regularly, and look for signs of wear or damage. To avoid
              problems during seasonal changes, replace worn-out chains or
              sprockets.
            </li>
            <li className="tipText">
              Storing and safeguarding: To avoid damage, keep your vehicle
              properly during harsh weather conditions such as heavy rain, snow,
              or extended periods of inactivity. Consider using a cover or
              storing it in a sheltered spot to preserve it from the elements.
            </li>
            <li className="tipText">
              General Examination: Lastly, conduct a general inspection of your
              vehicle for any signs of wear, damage, or loose bolts. Pay close
              attention to important components, including brakes, suspension,
              and electrical systems, and solve problems immediately to maintain
              safe and dependable performance.
            </li>
          </ol>
        </div>
        <div className="tipText">
          Finally, as Greasemonkey, we stress the significance of prepping your
          vehicle for seasonal changes to ensure the best performance, safety,
          and dependability. Regular maintenance and inspections and suitable
          changes and enhancements will let you ride safely and smoothly all
          year long, regardless of the weather.
        </div>
        <div style={{ margin: "5% 0" }}>
          <div className="tipCardText">YOU MIGHT ALSO LIKE</div>
          <div className="tipmotoMainBox">
            <div className="tipMotoBox">
              <img className="tipImg" src={imgz} />
              <div className="tipCardText">
                Which parts of your 4-wheeler should be changed within the time
                frame?
              </div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
            <div className="tipMotoBox">
              <img className="tipImg" src={imgz} />
              <div className="tipCardText">
                The benefits of regular oil changes for your vehicle: why you
                shouldn’t delay
              </div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
            <div className="tipMotoBox">
              <img className="tipImg" style={{ width: 300 }} src={imgz} />
              <div className="tipCardText">Standing Up With Frontliners</div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
          </div>
          <div
            style={{
              width: "40%",
              margin: "2% auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ margin: "2% 0" }} className="tipCardText">
              Leave a Reply
            </div>
            <textarea
              style={{
                border: "1px solid black",
                width: "100%",
                height: 100,
                margin: "5% 0",
              }}
            />
            <button style={{ width: "50%", backgroundColor: "#FFC701" }}>
              Post Comment
            </button>
          </div>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
}
