import React, { useEffect } from 'react';
import "./TermAndCondition.css"
import { FooterHomeSecond } from '../../components/FooterHome/FooterHomeSecond';
import { TopPage } from '../../components/TopPage/TopPage';
import { TopBar } from '../../TopBar/TopBar';

export const TermAndCondition = () => {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <div style={{backgroundColor:"#F1F0F1"}}>
        <TopBar/>
        <div className='termsBox'>
           <div className='termsText'><p>Grease Monkey Terms & Conditions</p></div>
           <div className='termsText'><p>Thank you for being a customer of Grease Monkey. Our purpose is to help our customers with their vehicle-related problems with various solutions where and when they want one. To do that, we need a set of clear agreements. We have put together these terms and conditions to clarify our commitments to each other.</p></div>
           <div className='termsText'><p>When we say “Company”, “Grease Monkey”, “we”, “our”, or “us” in this document, we are referring to Grease Monkey.
                  “Services” means any product or service provided by Grease Monkey. That includes Grease Monkey’s all versions of services such as vehicle servicing, vehicle insurance/ renewals, spare part replacements, selling/re-selling of vehicles, and many other services listed on Grease Monkey’s Website.</p></div>
           <div className='termsText'><p>Grease Monkey provides servicing including an oil and oil filter change, polishing, as well as checks to Brake Adjustment, Accelerator Cables Adjustment, Steering Adjustment-Steering Play Check, Clutch Cable Adjustment, Carburettor Cleaning, Sparkplug Cleaning, Additional Work Check-up, Accessory check and Adjust*, Complete Warring Check-up, Wheel Greasing, Complete Washing And Basic Polishing. It also includes a road test to ensure the proper functioning of the Vehicle and a Vehicle Health Card is provided with it. Any other required maintenance and servicing which is needed and not covered in servicing package such as Servicing tasks will be notified to the customer as the customer is liable to pay for the labor as well as for the spare parts whichever is needed at that time and after prior consultation with the customer Grease Monkey – at the Customer’s request – will coordinate the needed tasks with the relevant service partner.</p></div>
           <div className='termsText'><p>The additional fees for services or expenses which the Customer desires will be considered at the time of servicing. In any case, Grease Monkey is not liable to refund the Subscription package fee. Customers benefiting from a subscription are able to cancel their subscription at any time. All services from the package will be seized from the time of termination from the customer followed by any respected reason. You can cancel your subscription at any time by contacting Customer Support. We may amend these terms from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time.</p></div>
        </div>
        <FooterHomeSecond/>
    </div>
  );
}
