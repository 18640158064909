import React, { useEffect, useState } from 'react';
import { TopPage } from '../../components/TopPage/TopPage';
import { FooterHomeSecond } from '../../components/FooterHome/FooterHomeSecond';
import axios from 'axios';
import { Link } from 'react-router-dom';

export const ProductCategory = () => {
  const [plan, setPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://gmi-server-1.onrender.com/api/products/allproducts');
      setPlan(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredProducts = plan.filter((product) => {
    const currentRoute = window.location.pathname;
    if (currentRoute.includes('Car')) {
      return product.category === 'car' || product.category === 'breakdown';
    }
    if (currentRoute.includes('Bike')) {
      return product.category === 'bike' || product.category === 'breakdown';
    }
    
    return true;
  });


   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);


  return (
    <div style={{ backgroundColor: '#F1F0F1' }}>
      <TopPage />
      <div style={{width:"80%",margin:"4% 10%"}}>
        {isLoading && <div className="spinner-container"><div className="spinner"></div></div>}
        {!isLoading && (
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 45 }}>
            {filteredProducts.map((item) => (
              <Link to={`/product/${item._id}`} key={item._id} style={{ textDecoration: 'none', marginBottom: 40 }}>
                <div><img src={item.image} alt='product item' style={{ width: '100%', height: 250, }} /></div>
                <div style={{ fontSize: 15, fontWeight: 400, color: 'grey', textAlign: 'center' }}><p>{item.category}</p></div>
                <div style={{ fontSize: 20, fontWeight: 'bold', color: 'black', textAlign: 'center' }}><p>{item.product_name}</p></div>
                <div style={{ fontSize: 18, fontWeight: 400, color: '#6660B6', textAlign: 'center' }}><p>{item.price_tag}</p></div>
                <Link to={`/product/${item._id}`} style={{ textDecoration: 'none', backgroundColor: '#FCC701', padding: '5px 20px 5px 20px', fontSize: 15, color: 'white', fontWeight: 500,margin:"5% 30%"}}>Select Options</Link>
              </Link>
            ))}
          </div>
        )}
      </div>
      <FooterHomeSecond />
    </div>
  );
};
