import { useEffect, useState } from "react";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { SideMyAccount } from "../../components/MyAccount/SideMyAccount";
import axios from "axios";
import { TopBar } from "../../TopBar/TopBar";


export const MyAccount = () => {
  const [customerIdData, setCustomerIdData] = useState('');
  const [customerData, setCustomerData] = useState([]);


    const getDataFromStorage = async () => {
        try {
          const dataFromStorage = await localStorage.getItem('customer-user');
          if (dataFromStorage !== null) {
            const parsedData = JSON.parse(dataFromStorage);
            setCustomerIdData(parsedData);
          }
        } catch (error) {
          console.log('Error retrieving data from storage:', error);
        }
      };

      const fetchCustomerData = () => {
        axios
          .get(
            `https://gmi-server-1.onrender.com/api/customer/${customerIdData._id}`
          )
          .then((response) => {
            const data = response.data;
            setCustomerData(data);
          })
          .catch((error) => {
            console.error("Failed to fetch customer data:", error);
          });
      };    
      useEffect(() => {
        getDataFromStorage();
        fetchCustomerData()
      }, []);

       useEffect(() => {
         window.scrollTo(0, 0);
       }, []);

       
    return (
        <div style={{backgroundColor:"f0f0f0"}}>
            <TopBar />
            <div className="myAccountMainContainer">
                <SideMyAccount/>
                <div style={{width:"100%"}}><p>Hello {customerData.name} {" "}</p>
                     <p>From your account dashboard you can view your recent orders, manage your billing address, and edit your password and account details.</p>
                </div>
            </div>
            <FooterHomeSecond />
        </div>
    );

};
