import React, { useEffect } from "react";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { TopPage } from "../../components/TopPage/TopPage";
import "./TipFact1.css";
import { TopBar } from "../../TopBar/TopBar";
import imgz from "../../assests/mechanic-doodle.jpg";

export const TipFact1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ backgroundColor: "F1F0F1" }}>
      <TopBar />
      <div className="tipsBox">
        <div
          style={{
            fontSize: 30,
            fontWeight: "bold",
            color: "blacks",
            textAlign: "center",
          }}
        >
          The importance of regular vehicle servicing:
        </div>
        <div
          style={{
            fontSize: 30,
            fontWeight: "bold",
            color: "blacks",
            textAlign: "center",
          }}
        >
          Why you shouldn’t skip it
        </div>
        <div style={{ margin: "5% 0" }} className="tipText">
          We at Greasemonkey believe that frequent vehicle maintenance is
          critical for various reasons:
        </div>
        <div style={{ margin: "5% 0" }}>
          <ol type="1" style={{ lineHeight: 1.5 }}>
            <li className="tipText">
              Safety: Our primary focus is to ensure the safety of our
              customers. We can thoroughly inspect your vehicle’s brakes, tyres,
              and other essential components regularly, identifying and
              resolving any possible safety hazards before they become
              significant problems.
            </li>
            <li className="tipText">
              Performance: We recognize that our clients want their vehicles to
              be as efficient as possible. To maintain optimal performance, we
              examine the engine regularly and address any issues with fuel
              efficiency, power output, or acceleration.
            </li>
            <li className="tipText">
              Durability: We want our customers to be able to enjoy their
              vehicles for as long as possible. We can identify and address
              minor faults early on by maintaining regular servicing, avoiding
              them from developing severe issues that could cause irreparable
              damage and limit the vehicle’s lifespan.
            </li>
            <li className="tipText">
              Cost-effectiveness: We understand cost-effective solutions are
              important to our customers. Frequent maintenance allows for
              detecting and correcting minor issues before they grow into more
              costly fixes. Furthermore, a well-maintained vehicle has superior
              fuel efficiency and requires fewer repairs, saving our customers
              money in the long term on gasoline and maintenance expenditures.
            </li>
            <li className="tipText">
              Warranty: Many vehicle owners rely on warranties to provide
              additional protection. Regular servicing may void the guarantee,
              leaving customers liable for repairs that would otherwise be
              covered. To protect your investment, our team ensures that your
              vehicle maintenance follows warranty standards.
            </li>
          </ol>
        </div>
        <div className="tipText">
          To summarize, we at Greasemonkey believe frequent vehicle servicing is
          critical for safety, performance, longevity, cost-effectiveness, and
          warranty compliance. Don’t skip it if you want to keep your vehicle
          running smoothly and safely for years to come.
        </div>
        <div style={{ margin: "5% 0" }}>
          <div className="tipCardText">YOU MIGHT ALSO LIKE</div>
          <div className="tipmotoMainBox">
            <div className="tipMotoBox">
              <img className="tipImg" src={imgz} />
              <div className="tipCardText">
                The top 10 signs that your vehicle needs servicing.
              </div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
            <div className="tipMotoBox">
              <img className="tipImg" src={imgz} />
              <div className="tipCardText">
                What happens if I don’t service my vehicle?
              </div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
            <div className="tipMotoBox">
              <img className="tipImg" src={imgz} />
              <div className="tipCardText">
                The benefits of regular oil changes for your vehicle: why you
                shouldn’t delay
              </div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
          </div>
          <div
            style={{
              width: "40%",
              margin: "2% auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ margin: "2% 0" }} className="tipCardText">
              Leave a Reply
            </div>
            <textarea
              style={{
                border: "1px solid black",
                width: "100%",
                height: 100,
                margin: "5% 0",
              }}
            />
            <button style={{ width: "50%", backgroundColor: "#FFC701" }}>
              Post Comment
            </button>
          </div>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
