import React, { useEffect, useState } from "react";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { Register } from "../../components/RegisterLoginComponent/Register";
import { Login } from "../../components/RegisterLoginComponent/Login";
import { TopBar } from "../../TopBar/TopBar";
import "./RegisterLogin.css"

export const RegisterLogin = () => {
  const [showComponent1, setShowComponent1] = useState(true);
  const [showComponent2, setShowComponent2] = useState(false);

  const handleButtonClick1 = () => {
    setShowComponent1(true);
    setShowComponent2(false);
  };

  const handleButtonClick2 = () => {
    setShowComponent1(false);
    setShowComponent2(true);
  };

   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);

  return (
    <div>
      <TopBar/>
      <div className="registerLoginBox">
        <div
          className="LoginMainText"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <p style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={handleButtonClick1}
              style={{
                fontSize: 30,
                fontWeight: "bold",
                marginRight: "10px",
                backgroundColor: showComponent1 ? "#000" : "#FFF",
                color: showComponent1 ? "#FFF" : "#000",
              }}
            >
              Login
            </button>
            <span
              style={{
                borderRadius: "50%",
                width: "25px",
                height: "25px",
                padding: "8px",
                backgroundColor: "#fff",
                border: "2px solid #000",
                color: "#000",
                textAlign: "center",
                fontSize: 20,
                marginLeft: "10px",
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              OR
            </span>
            <button
              onClick={handleButtonClick2}
              style={{
                fontSize: 30,
                fontWeight: "bold",
                marginLeft: "10px",
                backgroundColor: showComponent2 ? "#000" : "#FFF",
                color: showComponent2 ? "#FFF" : "#000",
              }}
            >
              Register
            </button>
          </p>
        </div>

        {showComponent1 && <Login />}
        {showComponent2 && <Register />}
      </div>
      <FooterHomeSecond />
    </div>
  );
};
