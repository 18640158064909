import React, { useEffect, useState } from "react";
import "./TopPage.css";
import image from "../../assests/main-background.png";
import logo from "../../assests/gmi-logo.png";
import menu from "../../assests/menu.png";
import { Link, useNavigate } from "react-router-dom";

export const TopPage = () => {
   const [menuOpen, setMenuOpen] = useState(false);
   const navigation = useNavigate();
   const toggleMenu = () => {
     setMenuOpen(!menuOpen);
   };
   var [customerIdData, setCustomerIdData] = useState("");

   useEffect(() => {
     const storedData = JSON.parse(localStorage.getItem("customer-user"));
     setCustomerIdData(storedData);
   }, []);

   const handleLogout = (e) => {
     e.preventDefault();
     if (window.confirm("Are you sure you want to log out?")) {
       localStorage.removeItem("customer-user");
       navigation("/login");
     }
   };

   const handleLogin = (e) => {
     e.preventDefault();
     if (window.confirm("You Need to Log in First?")) {
       navigation("/login");
     }
   };

   return (
     <div
       className={`topbox ${menuOpen ? "menu-open" : ""}`}
       style={{
         backgroundImage: `url(${image})`,
         backgroundRepeat: "repeat",
         backgroundSize: "contain",
         height: 370,
         maxWidth: "100%",
         // border: "5px solid red",
       }}
     >
       <div className="topbarContainer">
         <div>
           <Link to={"/"}>
             <img className="imgLogo" src={logo} alt="Italian Trulli" />
           </Link>
         </div>
         <img
           onClick={toggleMenu}
           className="menuLogo"
           src={menu}
           alt="Italian Trulli"
         />
       </div>

       <nav className="side-menu">
         <button onClick={toggleMenu} className="close-button">
           ✕
         </button>
         <div
           style={{
             display: "flex",
             flexDirection: "column",
             marginLeft: 20,
             marginTop: 30,
             gap: 15,
             // border: "1px solid red",
             width: "100%",
           }}
         >
           <Link to={"/about-us"} className="otherLinks">
             About us
           </Link>
           <Link to={"/contact-us"} className="otherLinks">
             Contact us
           </Link>
           {customerIdData ? (
             <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
               <Link to={"/cart"} className="otherLinks">
                 Cart
               </Link>
               <Link to={"/addmyvehicle"} className="otherLinks">
                 Add vehicle
               </Link>
               <Link to={"/my-account"} className="otherLinks">
                 My account
               </Link>
               <Link onClick={handleLogout} className="loginBtn">
                 Log out
               </Link>

               <Link to={"/testing"} className="loginBtn">
                 Testing
               </Link>
             </div>
           ) : (
             <div style={{ marginTop: 10 }}>
               <Link
                 onClick={handleLogin}
                 className="loginBtn"
                 style={{ padding: "3% 60% 3% 4%" }}
               >
                 Log in
               </Link>
             </div>
           )}
         </div>
       </nav>

       <div className="centeredContent">
         <p className="titleText">
           Bike ECG is now <span className="highlightText">Grease Monkey</span>
         </p>
       </div>
     </div>
   );
 };
