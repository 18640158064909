import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useNavigation, useParams } from "react-router-dom";
import "./RelatedProduct.css";

export const RelatedProduct = ({ item }) => {
  // const { item } = useParams();
  // console.log("item:>>>>>>>>>>>>>", item.category);

  const navigate = useNavigate();
  const [plan, setPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const selectedCategory = item.category;
  console.log("filteredData:>>>>>>>>>>>>>", filteredData);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://gmi-server-1.onrender.com/api/products/allproducts"
      );
      const newData = response.data;

      const selectedCategory = item.category;
      setPlan(newData);
      const filteredCategory = newData.filter(
        (i) => i.category === selectedCategory
      );
      // Update state with filtered data
      setFilteredData(filteredCategory);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div
      style={{ width: "80%", margin: "auto", marginBottom: 130, marginTop: 30 }}
    >
      <div>
        <p className="productNameText">Related products</p>
      </div>
      {isLoading && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}
      {!isLoading && (
        <div className="relatedProBox">
          {filteredData.map((item) => (
            <Link
              to={`/product/${item._id}`}
              key={item._id}
              style={{ textDecoration: "none", marginBottom: 20 }}
              className="RelatedSingleItem"
            >
              <div>
                <img
                  // src={item.image}
                  src={
                    item.image
                      ? require(`../../assests/${item.image}`)
                      : "Loading Image..."
                  }
                  alt="product item"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <p
                style={{
                  fontSize: 15,
                  fontWeight: 400,
                  color: "grey",
                  textAlign: "center",
                }}
              >
                {item.category}
              </p>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "black",
                  textAlign: "center",
                }}
              >
                {item.product_name}
              </p>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: 400,
                  color: "#6660B6",
                  textAlign: "center",
                }}
              >
                {item.price_tag}
              </p>
              <div
                style={{ marginTop: 25, margin: "auto" }}
                className="selectBtn"
              >
                <Link
                  // navigate(`/product/${item._id}`, {state:{item}});
                  onClick={() => navigate(`/product/${item._id}`, {state:{item}})}
                  // to={`/product/${item._id}`}
                  style={{
                    textDecoration: "none",
                    backgroundColor: "#FCC701",
                    fontSize: 15,
                    color: "white",
                    fontWeight: 500,
                    paddingLeft: 15,
                  }}
                >
                  Select Options
                </Link>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
