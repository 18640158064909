import React from "react";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { AllProducts } from "../../components/AllProducts/AllProducts";
import { TopBar } from "../../TopBar/TopBar";
import { useLocation } from "react-router-dom";

export const Shop = () => {
  const location = useLocation();
  const { state } = location;
  const { item } = state || {}; // Destructure item from state, fallback to an empty object

  console.log("Item in Shop page:", item);

  const category = item?.category;

  return (
    <div style={{ backgroundColor: "F1F0F1" }}>
      <TopBar />
      <AllProducts category={category} />
      <FooterHomeSecond />
    </div>
  );
};
