import React from "react";
import "./checkout.css";
import { useNavigate } from "react-router-dom";
import { FooterHomeSecond } from "../FooterHome/FooterHomeSecond";
import { TopPage } from "../TopPage/TopPage";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { TopBar } from "../../TopBar/TopBar";

export const Checkout = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [cusemail, setEmail] = useState("");
  const products = JSON.parse(searchParams.get("products"));
  console.log("products>>>>>>>>>>>>>>>>>>>", products);
  const finalsubTotal = parseFloat(searchParams.get("finalsubTotal"));
  const finalTotal = parseFloat(searchParams.get("finalTotal"));
  const discount = parseFloat(searchParams.get("discount"));
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   console.log("products:", products);
  //   console.log("finalSubTotal:", finalSubTotal);
  //   console.log("finalTotal:", finalTotal);
  //   console.log("discount:", discount);
  //   // You can further process the data as needed
  // }, [products, finalSubTotal, finalTotal, discount]);

  // const { products } = "data";
  var [customerIdData, setCustomerIdData] = useState("");
  const [address, setAddress] = useState("");

  const [formData, setFormData] = useState({
    customer_name: "",
    product_name: "",
    category: "",
    status: "",
    discription: "",
    total: "",
    final_total: "",
    image: "",
    vehicle_type: "",
    make_model: "",
    verify_make_model: "",
    reg_number: "",
    verify_reg_number: "",
    servicing_date: "",
    servicing_time: "",
    location: "",
    city: "",
    state: "",
    pincode: "",
    note: "",
    cusNumber: "",
    total_price: "",
    // customerIdData: "",
  });
  console.log("formdata:", formData);

  const handleSetFormData = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      customer_name: customerIdData?.name || formData.customer_name,
      product_name: products.map((product) => product || []),
      category: products[0]?.category || "",
      status: products[0]?.status || "",
      discription: products[0]?.discription || "",
      total: products[0]?.total || 0,
      final_total: finalTotal || 0,
      image: products[0]?.image || "",
      vehicle_type:
        products[0]?.vehicle_type || userDataLoc.vehicleData.vehicle_type,
      make_model: products[0]?.make_model || userDataLoc.vehicleData.make_model,
      verify_make_model: true,
      reg_number: products[0]?.reg_number || userDataLoc.vehicleData.reg_number,
      verify_reg_number: true,
      servicing_date: new Date(products[0]?.servicing_date) || "",
      servicing_time: products[0]?.servicing_time || "",
      location: products[0]?.location || "",
      city: products[0]?.city || "",
      state: products[0]?.state || "",
      pincode: products[0]?.pincode || "",
      note: products[0]?.note || "",
      cusNumber: customerIdData?.phone || "",
      // total_price: finalTotal || "",
    }));
  };

  useEffect(() => {
    handleSetFormData();
    loadAddressFromlocalStorage();
  }, [customerIdData]);

  // Function to load address data from localStorage
  const loadAddressFromlocalStorage = async () => {
    try {
      const savedAddress = await localStorage.getItem("addressKey");
      if (savedAddress) {
        setAddress(savedAddress);
      }
    } catch (error) {
      console.error("Error loading address from localStorage:", error);
    }
  };

  const updatedOrderData = {
    myOrders: formData,
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const customerId = customerIdData?._id;
      if (!customerId) {
        console.error("No customer ID found");
        return;
      }
      const response = await axios.put(
        `https://gmi-server-1.onrender.com/api/customer/myorder/${customerId}`,
        { myOrders: formData },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // alert("Data updated successfully!");
        setIsLoading(false);
      } else {
        alert("Error updating data. Please try again.");
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle cancelation
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error submitting order:", error.message);
      }
    }
    setIsLoading(false);
  };

  const handleChange = (field, value) => {
    if (field === "servicing_time") {
      // Format the time and update the servicing_time field
      const formattedTime = selectedDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: formattedTime,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: value,
      }));
    }
  };

  const sendOrderMail = async () => {
    const orderMailEndpoint =
      "https://gmi-server-1.onrender.com/api/email/user/order-mail";

    const orderData = {
      userEmail: cusemail,
      customer_name: formData.customer_name,
      product_name: formData.product_name[0].product_name,
      final_total: formData.final_total,
      location: formData.location,
    };
    try {
      const response = await axios.post(orderMailEndpoint, orderData);
      // Handle success if needed
    } catch (error) {
      console.error(error); // Log the error
      // Handle error if needed
    }
  };

  const handleGeneratemyOrder = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://gmi-server-1.onrender.com/api/newmyorder/generate",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        alert("Data updated successfully!!");
        setIsLoading(false);
        sendOrderMail();
      } else {
        alert("Error updating data. Please try again.");
      }

      const data = response.data;
    } catch (error) {
      console.error("Error submitting order:", error.message);
    }
    setIsLoading(false);
    setFormData({
      customer_name: formData.customer_name,
      product_name: "",
      category: "",
      status: "",
      discription: "",
      total: "",
      final_total: "",
      image: "",
      vehicle_type: userDataLoc.vehicleData.vehicle_type,
      make_model: userDataLoc.vehicleData.make_model,
      verify_make_model: true,
      reg_number: userDataLoc.vehicleData.reg_number,
      verify_reg_number: true,
      servicing_date: "",
      servicing_time: "",
      location: "",
      city: "",
      state: "",
      pincode: "",
      note: "",
      cusNumber: "",
      total_price: "",
    });
  };

  const handleCombinedButtonClick = async () => {
    try {
      await handleSubmit();
      await handleGeneratemyOrder();
      navigate("/order-received");
    } catch (error) {
      console.error("Error in combined button click:", error);
    }

    handleDeleteAllCartData();
  };

  const handleDeleteAllCartData = async () => {
    try {
      if (customerIdData && customerIdData._id) {
        var cusID = customerIdData._id;
        const response = await axios.delete(
          `https://gmi-server-1.onrender.com/api/customer/${cusID}/all_cart_delete`
        );
        console.log(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch customer data1: Error", error.message);
    }
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("customer-user"));
    setCustomerIdData(storedData);
  }, []);

  const [myVehiclesData, setMyVehiclesData] = useState([]);
  // console.log('myVehiclesData::::>>>>>xx:', myVehiclesData);

  const fetchCustomerData = async () => {
    try {
      if (customerIdData && customerIdData._id) {
        var cusID = customerIdData._id;
        const response = await axios.get(
          `https://gmi-server-1.onrender.com/api/customer/${cusID}`
        );
        const data = response.data;
        setMyVehiclesData(data.vehicles);
      }
    } catch (error) {
      console.error("Failed to fetch customer data1: Error", error.message);
    }
  };

  useEffect(() => {
    if (customerIdData && customerIdData._id) {
      fetchCustomerData();
    }
  }, [customerIdData]);

  ///////////////////////////////////

  const [show, setShow] = useState(false);
  const [mode, setMode] = useState("date");

  const [selectedDate, setSelectedDate] = useState(new Date()); // Add a new state variable

  ////////////////////////

  const [tPrice, settPrice] = useState(0);

  const finalPrice = formData.total + tPrice;

  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx  date/////

  /// get localstorage data>>>>>

  const [userDataLoc, setUserDataLoc] = useState({
    vehicleData: {},
    userData: {},
  });
  // console.log("userDataLoc:", userDataLoc);

  useEffect(() => {
    const storedVehicleData = JSON.parse(
      localStorage.getItem("customerVehicle")
    );
    const storedUserData = JSON.parse(localStorage.getItem("customer-user"));

    // Assuming both data types are objects, set them in userDataLoc
    setUserDataLoc({
      vehicleData: storedVehicleData || {},
      userData: storedUserData || {},
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div style={{ backgroundColor: "#F1F0F1" }}>
      <TopBar />
      <div className="containerCheckout">
        <div className="billing-details">
          <h3>Billing details</h3>
          {/* <div style={{ display: "flex" }}> */}

          <div className="checkoutDataBox">
            <div className="fieldBox">
              <div className="fieldTitle">
                <p>Full name</p>
              </div>
              <div className="inputField">
                <p>{formData.customer_name}</p>
              </div>
            </div>
            <div className="fieldBox">
              <div className="fieldTitle">
                <p>Email address</p>
              </div>
              <div className="inputField">
                <p>{userDataLoc.userData.email}</p>
              </div>
            </div>
            <div className="fieldBox">
              <div className="fieldTitle">
                <p>Vehicle type</p>
              </div>
              <div className="inputField">
                <p>{userDataLoc.vehicleData.vehicle_type}</p>
              </div>
            </div>
            {/* <div className="fieldBox">
              <div className="fieldTitle">
                <p>Vehicle brand</p>
              </div>
              <div className="inputField">
                <p>{userDataLoc.vehicleData.make}</p>
              </div>
            </div> */}
            <div className="fieldBox">
              <div className="fieldTitle">
                <p>Vehicle make & model</p>
              </div>
              <div className="inputField">
                <p>{userDataLoc.vehicleData.make_model}</p>
              </div>
            </div>
            <div className="fieldBox">
              <div className="fieldTitle">
                <p>Vehicle Register No.</p>
              </div>
              <div className="inputField">
                <p>{userDataLoc.vehicleData.reg_number}</p>
              </div>
            </div>
          </div>

          {/* <div className="checkoutDataBox">
            <div className="fieldBox">
              <div className="fieldTitle"><p>Full name</p></div>
              <div className="inputField">
                <input
                  type="text"
                  value={formData.customer_name}
                  onChange={(e) => handleChange("customer_name", e.target.value)}
                  placeholder="Full name*" /></div>
            </div>
            <div className="fieldBox">
              <div className="fieldTitle"><p>Email address</p></div>
              <div className="inputField"><input
            type="text"
            // value={cusemail}
            value={userDataLoc.userData.email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email *"
          /></div>
            </div>
            <div className="fieldBox">
              <div className="fieldTitle"><p>Vehicle type</p></div>
              <div className="inputField"><input
            type="text"
            // value={formData.vehicle_type}
            value={userDataLoc.vehicleData.vehicleType}
            onChange={(e) => handleChange("vehicle_type", e.target.value)}
            placeholder="vehicle_type *"
          /></div>
            </div>
            <div className="fieldBox">
              <div className="fieldTitle"><p>Vehicle brand</p></div>
              <div className="inputField"><input
            type="text"
            value={userDataLoc.vehicleData.make}
            onChange={(e) => handleChange("make_model", e.target.value)}
            placeholder="make_model *"
          /></div>
            </div>
            <div className="fieldBox">
              <div className="fieldTitle"><p>Vehicle model</p></div>
              <div className="inputField"><input
            type="text"
            value={userDataLoc.vehicleData.model}
            onChange={(e) => handleChange("vehicle_model", e.target.value)}
            placeholder="vehicle_model *"
          /></div>
            </div>
            <div className="fieldBox">
              <div className="fieldTitle"><p>Vehicle Register No.</p></div>
              <div className="inputField"><input
            type="text"
            value={userDataLoc.vehicleData.reg_number}
            onChange={(e) => handleChange("reg_number", e.target.value)}
            placeholder="reg_number *"
          /></div>
            </div>
          </div> */}
          {/* <input
            type="text"
            value={formData.customer_name}
            onChange={(e) => handleChange("customer_name", e.target.value)}
            placeholder="Full name*"
          />
          <input
            type="text"
            // value={cusemail}
            value={userDataLoc.userData.email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email *"
          />
          <input
            type="text"
            // value={formData.vehicle_type}
            value={userDataLoc.vehicleData.vehicleType}
            onChange={(e) => handleChange("vehicle_type", e.target.value)}
            placeholder="vehicle_type *"
          />
          <input
            type="text"
            value={userDataLoc.vehicleData.make}
            onChange={(e) => handleChange("make_model", e.target.value)}
            placeholder="make_model *"
          />
          <input
            type="text"
            value={userDataLoc.vehicleData.model}
            onChange={(e) => handleChange("vehicle_model", e.target.value)}
            placeholder="vehicle_model *"
          />
          <input
            type="text"
            value={userDataLoc.vehicleData.reg_number}
            onChange={(e) => handleChange("reg_number", e.target.value)}
            placeholder="reg_number *"
          /> */}
          <div className="inputFieldDataBox">
            <label
              htmlFor="servicingDate"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "80%",
                margin: "0 0 0.2% 1%",
              }}
            >
              Date & Time of servicing
              <span
                style={{ color: "red", fontSize: "small", marginLeft: "5px" }}
              >
                *
              </span>
            </label>
            <div className="dataTimeBox">
              <input
                type="date"
                onChange={(e) => handleChange("servicing_date", e.target.value)}
                placeholder="Servicing Date *"
              />

              <input
                type="time"
                onChange={(e) => handleChange("servicing_time", e.target.value)}
                placeholder="Servicing Time *"
              />
            </div>

            <label
              htmlFor="servicingDate"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "80%",
                margin: "2% 0 0.2% 1%",
              }}
            >
              Address
              <span
                style={{ color: "red", fontSize: "small", marginLeft: "5px" }}
              >
                *
              </span>
            </label>
            <input
              type="text"
              value={formData.location}
              onChange={(e) => handleChange("location", e.target.value)}
              placeholder="Street address *"
            />

            <label
              htmlFor="servicingDate"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "80%",
                margin: "2% 0 0.2% 1%",
              }}
            >
              City
              <span
                style={{ color: "red", fontSize: "small", marginLeft: "5px" }}
              >
                *
              </span>
            </label>
            <input
              type="text"
              value={formData.city}
              onChange={(e) => handleChange("city", e.target.value)}
              placeholder="City *"
            />
            <label
              htmlFor="servicingDate"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "80%",
                margin: "2% 0 0.2% 1%",
              }}
            >
              State
              <span
                style={{ color: "red", fontSize: "small", marginLeft: "5px" }}
              >
                *
              </span>
            </label>
            <input
              type="text"
              value={formData.state}
              onChange={(e) => handleChange("state", e.target.value)}
              placeholder="State  *"
            />
            <label
              htmlFor="servicingDate"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "80%",
                margin: "2% 0 0.2% 1%",
              }}
            >
              Pincode
              <span
                style={{ color: "red", fontSize: "small", marginLeft: "5px" }}
              >
                *
              </span>
            </label>
            <input
              type="number"
              value={formData.pincode}
              onChange={(e) => handleChange("pincode", e.target.value)}
              placeholder="Pincode *"
            />
            <label
              htmlFor="servicingDate"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "80%",
                margin: "2% 0 0.2% 1%",
              }}
            >
              Phone No.
              <span
                style={{ color: "red", fontSize: "small", marginLeft: "5px" }}
              >
                *
              </span>
            </label>
            <input
              type="number"
              value={formData.phone}
              onChange={(e) => handleChange("cusNumber", e.target.value)}
              placeholder="Phone *"
            />
            <label
              htmlFor="servicingDate"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "80%",
                margin: "2% 0 0.2% 1%",
              }}
            >
              Note
              {/* <span
                style={{ color: "red", fontSize: "small", marginLeft: "5px" }}
              >
                *
              </span> */}
            </label>
            <input
              type="text"
              value={formData.note}
              onChange={(e) => handleChange("note", e.target.value)}
              placeholder="Note *"
            />
          </div>
        </div>
        <div className="order">
          <h3>Your order</h3>
          <table style={{ backgroundColor: "white" }}>
            <thead>
              <tr>
                <th>PRODUCT</th>
                <th>SUBTOTAL</th>
              </tr>
            </thead>
            <tbody>
              {products &&
                products.map((item, index) => (
                  <tr key={item?._id + index}>
                    <td>
                      {index + 1}. {item?.product_name}
                    </td>
                    <td style={{ textAlign: "center" }}>₹ {item?.total}</td>
                  </tr>
                ))}
              <tr>
                <td style={{ textAlign: "left" }}>Discount</td>
                <td style={{ textAlign: "center", color: "red" }}>
                  -₹ {discount}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", textAlign: "left" }}>
                  Total Amount
                </td>
                <td style={{ fontWeight: "bold", textAlign: "center" }}>
                  ₹ {finalTotal}/-
                </td>
              </tr>
            </tbody>
          </table>

          <div className="textBox">
            <div className="radioBox1" style={{ padding: 10 }}>
              <div className="radioBox">
                <input type="radio" checked />
                <p style={{ marginLeft: 10, marginTop: 20 }}>
                  Pay on Order Completion.{" "}
                </p>
              </div>
              <p style={{ marginLeft: 8 }}>
                Pay on Order Completion with UPI, NEFT, IMPS, Credit Card, or
                Debit Card.
              </p>
            </div>
            <p>
              Your personal data will be used to process your order, support
              your experience throughout this website, and for other purposes
              described in our privacy policy.
            </p>
            <div className="checkedBox">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={() => {
                  setIsChecked(!isChecked);
                }}
              />
              <p style={{ marginLeft: 10, marginTop: 22 }}>
                I have read and agree to the website terms and conditions *
              </p>
            </div>
          </div>

          <button
            type="submit"
            onClick={handleCombinedButtonClick}
            className={`orderButton ${!isChecked ? "disabled" : ""}`}
            disabled={!isChecked}
            style={{ fontSize: "100%", fontWeight: "bold", borderRadius: "10px" }}
          >
            {isLoading ? "Updating..." : "Place order"}
          </button>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
