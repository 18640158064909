import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Test = () => {
  const [customerIdData, setCustomerIdData] = useState("");
  const [reg_number, setreg_number] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    getVehicleData();
  }, []);

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  console.log("selectedData:", selectedData);

  const handleUpdate = async () => {
    setIsLoading(true);

    // console.log("selectedData:", selectedData);

    //  if (!vehicleType || !make || !model || !cc_type || !reg_number) {
    //    alert("Please fill in all the fields.");
    //    setIsLoading(false);
    //    return;
    //  }

    const vehicleData = {
      vehicleType: selectedData.vehicle_type,
      make: selectedData.make_model,
      model: selectedData.vehicle_model,
      cc_type: selectedData.vehicle_cc,
      reg_number,
    };
    //  console.log("vehicleData>>:", vehicleData);

    try {
      await axios.put(
        `https://bike-server2.onrender.com/api/customer/addvehicle/${customerIdData}`,
        vehicleData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      alert("Success", "Vehicle data added successfully.");
      window.history.back();
    } catch (error) {
      console.log("Error:", error);
      setIsLoading(false);
      alert("Error", "Failed to add vehicle data.");
    }
  };

  const getDataFromStorage = async () => {
    try {
      const dataFromStorage = await localStorage.getItem("customer-user");
      if (dataFromStorage !== null) {
        const parsedData = JSON.parse(dataFromStorage);
        setCustomerIdData(parsedData._id);
      }
    } catch (error) {
      console.log("Error retrieving data from storage:", error);
    }
  };

  useEffect(() => {
    getDataFromStorage();
  }, []);

  const getVehicleData = () => {
    axios
      .get("https://bike-server2.onrender.com/api/vehicles/allvehicles")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSelection = (vehicle) => {
    // Here you can perform any actions with the selected data
    setSelectedData(vehicle);
  };

  const filteredData = data.filter((vehicle) => {
    const makeModel =
      `${vehicle.make_model} ${vehicle.vehicle_model}`.toLowerCase();
    return makeModel.includes(searchTerm.toLowerCase());
  });

  return (
    <div>
      <div style={{ margin: "5%" }}>
        <div className="addVehicleContainer">
          <div className="addVehicleSubContainer">
            <div>
              <p style={{ fontSize: "150%" }}>Bike registration information</p>
            </div>
            <div className="addVehcileNumBox">
              <div className="selectTextBox">
                <p className="vehicleNumText">Registration Number</p>
              </div>
              <div className="selectBox">
                <input
                  style={{ borderRadius: 5 }}
                  name="colors"
                  className="basic-multi-select"
                  value={reg_number}
                  onChange={(e) => setreg_number(e.target.value)}
                  placeholder="MH 41 AZ 0000"
                />
              </div>
            </div>
            <div className="addVehcileNumBox">
              <div className="selectTextBox">
                <p className="vehicleNumText">Vehicle Make & Model</p>
              </div>
              <div className="selectBox">
                <input
                  type="text"
                  placeholder="Search by make or model"
                  value={
                    selectedData
                      ? `${selectedData.make_model} ${selectedData.vehicle_model}`
                      : searchTerm
                  }
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div>
              <div
                style={{
                  overflowY: "scroll",
                  maxHeight: "100px",
                  cursor: "pointer",
                }}
              >
                {filteredData.map((vehicle) => (
                  <div
                    key={vehicle._id}
                    onClick={() => handleSelection(vehicle)}
                  >
                    <p>{`${vehicle.make_model} ${vehicle.vehicle_model}`}</p>
                  </div>
                ))}
              </div>
              {selectedData && (
                <div>
                  <p>Selected Data:</p>
                  <p>{`${selectedData.make_model} ${selectedData.vehicle_model}`}</p>
                </div>
              )}
            </div>
          </div>
          <div style={{ marginTop: "15%", marginRight: 20 }}>
            <img src="https://resources.turtlemint.com/images/registration.svg" />
          </div>
        </div>
        <div className="BtnBox">
          <button
            style={{
              backgroundColor: "#FFC224",
              fontSize: "90%",
              fontWeight: "bold",
            }}
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </button>
          <button
            style={{
              backgroundColor: "#FFC224",
              fontSize: "90%",
              fontWeight: "bold",
            }}
            onClick={handleUpdate}
          >
            {isLoading ? <span>Uploading...</span> : <span>Add Vehicle</span>}
          </button>
        </div>
      </div>
    </div>
  );
};
