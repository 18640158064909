import axios from "axios";
import "./SingleProduct.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import bike from "../../assests/bike.png";
import car from "../../assests/car.png";

export const SingleProduct = ({ id }) => {
  let navigate = useNavigate();

  const [selectedCC, setSelectedCC] = useState(0);
  const [selectedCity, setSelectedCity] = useState(0);
  const [selectedVihicleName, setSelectedVihicleName] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const [total, setTotal] = useState(0);
  const [vehicles, setVehicles] = useState([]);
  console.log("vehicles::", vehicles);
  const [showSubPoints, setShowSubPoints] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [cusData, setcusData] = useState("");
  const [citySegmentData, setCitySegmentData] = useState("");
  // const [selectedMake, setSelectedMake] = useState(null);
  const [city, setcity] = useState("");
  // console.log("total", city, cusData);

  const updateTotal = () => {
    const newTotal = parseInt(selectedCC) + selectedCity;
    setTotal(newTotal);
  };

  const handleCCChange = (selectedOption) => {
    setSelectedCC(selectedOption.value);
    setSelectedVihicleName(selectedOption.label);
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption.value);
    setSelectedCityName(selectedOption.label);
  };

  useEffect(() => {
    updateTotal();
  }, [selectedCC, selectedCity]);

  const [product, setProduct] = useState([]);
  console.log("product>>>>>>>", product);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [activeContent, setActiveContent] = useState("Content 1");
  const [activeButton, setActiveButton] = useState("Button 1");
  const [dataToUpdate, setDataToUpdate] = useState({
    _id: "",
    product_name: "",
    category: "",
    status: "",
    description: "",
    bike_cc: "",
    car_segment: "",
    city: "",
    createdAt: "",
    image: "",
    price_tag: "",
    total: 0,
  });
  console.log("dataToUpdate:", dataToUpdate);

  const showDescription = () => {
    setActiveContent("Content 1");
    setActiveButton("Button 1");
  };

  const showCC = () => {
    setActiveContent("Content 2");
    setActiveButton("Button 2");
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://gmi-server-1.onrender.com/api/products/${id}`
      );
      setProduct(response.data);
      setDataToUpdate({
        ...response.data,
        total: 0,
        city: 0,
        bike_cc: 0,
        car_segmen: 0, // Initialize total to 0
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      getVehicleData();
    }
  };

  useEffect(() => {
    setDataToUpdate((prevState) => ({
      ...prevState,
      total: total,
      bike_cc: selectedVihicleName,
      car_segment: selectedVihicleName,
      city: selectedCityName,
    }));
  }, [total, selectedVihicleName, selectedCityName]);

  const handleAddToCart = async () => {
    setIsLoadingBtn(true);
    try {
      if (cusData) {
        const response = await axios.put(
          `https://gmi-server-1.onrender.com/api/customer/cart/${cusData._id}`,
          dataToUpdate,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        navigate("/cart");
        await handleUpdateVehicleData();
      }
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
    setIsLoadingBtn(false);
  };

  // var [cusData, setcusData] = useState("");
  // console.log(cusData._id);
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("customer-user"));
    setcusData(storedData);
  }, []);

  useEffect(() => {
    if (cusData._id) {
      fetchData();
      getVehicleData();
    }
  }, [cusData._id]);

  /////////////////////////   vehicle      >>>>>>>>
  // const getVehicleData = async () => {
  //   try {
  //     if (cusData._id) {
  //       const response = await axios.get(
  //         `https://gmi-server-1.onrender.com/api/customer/${cusData._id}`
  //       );
  //       setVehicles(response.data.vehicles);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const [selectedMake, setSelectedMake] = useState(null);
  // console.log("selectedMake:", selectedMake);
  const [selectedvehicle, setSelectedvehicle] = useState({});
  console.log("selectedvehicle:", selectedvehicle);

  const handleUpdateVehicleData = () => {
    localStorage.setItem("customerVehicle", JSON.stringify(selectedvehicle));
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    // Enable the button only if both total and city have values
    setIsButtonDisabled(!(total && city));
  }, [total, city]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("customer-user"));
    setcusData(storedData);
  }, []);

  useEffect(() => {
    if (cusData._id) {
      fetchData();
      getVehicleData();
    }
    setTotal(0);
  }, [cusData._id, city]);

  const getVehicleData = async () => {
    try {
      if (cusData._id) {
        const response = await axios.get(
          `https://gmi-server-1.onrender.com/api/customer/${cusData._id}`
        );
        setVehicles(response.data.vehicles);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectCity = (event) => {
    setcity(event.target.value);
    const selectedCity = event.target.value;

    if (product.category === "Bike") {
      const cityData = product[selectedCity]?.[0]?.bike_segment;

      if (cityData && Array.isArray(cityData)) {
        setCitySegmentData(cityData);
      }
    }

    if (product.category === "Car") {
      const cityData = product[selectedCity]?.[0]?.car_segment;

      if (cityData && Array.isArray(cityData)) {
        setCitySegmentData(cityData);
      }
    }
  };

  const handleSelectVehicle = (vehicle) => {
    setSelectedMake(vehicle._id);
    setSelectedvehicle(vehicle);
    const ccTypeNumber = parseInt(vehicle.bike_cc, 10);
    displayCitySegmentData(ccTypeNumber);

    const carType = vehicle.car_type;
    displayCitySegmentCarData(carType);
  };

  const displayCitySegmentCarData = (selectedCarType) => {
    const Ctype = selectedCarType;

    if (citySegmentData && Array.isArray(citySegmentData)) {
      let selectedData;

      if (Ctype === "Hatchback") {
        selectedData = citySegmentData[0];
      } else if (Ctype === "Sedan") {
        selectedData = citySegmentData[1];
      } else if (Ctype === "SUV") {
        selectedData = citySegmentData[2];
      }

      if (selectedData) {
        console.log("Selected City Segment Data:", selectedData);
        const keys = Object.keys(selectedData);
        const value = keys.length > 0 ? selectedData[keys[0]] : undefined;

        setTotal(value !== undefined ? value : 0);
      }
    }
  };

  const displayCitySegmentData = (selectedNumber) => {
    const num = parseInt(selectedNumber, 10);

    if (citySegmentData && Array.isArray(citySegmentData)) {
      let selectedData;

      // <<<<<<< Updated upstream

      if (num >= 90 && num < 150) {
        selectedData = citySegmentData[0];
      } else if (num >= 150 && num <= 200) {
        selectedData = citySegmentData[1];
      } else if (num > 200) {
        selectedData = citySegmentData[2];
      }

      if (selectedData) {
        // console.log("Selected City Segment Data:", selectedData);
        const keys = Object.keys(selectedData);
        const value = keys.length > 0 ? selectedData[keys[0]] : undefined;

        setTotal(value !== undefined ? value : 0);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {isLoading && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}
      {!isLoading && (
        <div className="detailContainer">
          <div className="prodDetailBox">
            <div className="proImg">
              <img
                src={
                  product.image
                    ? require(`../../assests/${product.image}`)
                    : "Loading Image..."
                }
                alt="Product Image..."
                className="singleProductImg"
              />
            </div>
            <div className="productRight">
              <div>
                <p className="productName">{product.product_name}</p>
              </div>

              <div className="cityBox">
                <p className="productNameText">
                  Select City <span style={{ color: "red" }}>*</span>
                </p>

                <select value={city} onChange={handleSelectCity}>
                  <option value="">Select One</option>
                  <option value="nagpur">Nagpur</option>
                  <option value="pune">Pune</option>
                </select>
              </div>

              <div className="vegicleBox">
                <div>
                  <p className="productNameText">
                    Select Vehicle <span style={{ color: "red" }}>*</span>
                  </p>
                </div>
                <div className="myVehicleList">
                  {vehicles
                    .filter(
                      (item) =>
                        item.vehicle_type === product.category.split(" ")[0]
                    )
                    .map((item) => (
                      <div
                        key={item._id}
                        onClick={() => handleSelectVehicle(item)}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: "100%",
                          width: "100%",
                          border: "1px solid #ddd",
                          cursor: "pointer",
                          borderRadius: 10,
                          backgroundColor:
                            selectedMake === item._id
                              ? "rgb(49,86,159)"
                              : "white",
                          color: selectedMake === item._id ? "white" : "black",
                        }}
                      >
                        <div
                          style={{
                            width: "35%",
                            height: "auto",
                            marginRight: "2%",
                            marginTop: "5%",
                            // border: "1px solid red",
                          }}
                        >
                          {/* <img
                            src={item.vehicle_image}
                            style={{ height: "100%", width: "100%" }}
                          /> */}
                          {product.category.split(" ")[0] === "Bike" ? (
                            <img
                              src={bike}
                              style={{ height: "100%", width: "100%" }}
                            />
                          ) : (
                            <img
                              src={car}
                              style={{ height: "100%", width: "100%" }}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            width: "55%",
                            height: 60,
                            marginTop: "5%",
                          }}
                        >
                          <div
                            style={{
                              marginTop: "5%",
                              fontSize: 12,
                              color: "black",
                              fontWeight: "bold",
                              color:
                                selectedMake === item._id ? "white" : "black",
                            }}
                          >
                            {item.make_model}
                          </div>
                          <div
                            style={{
                              marginTop: "15%",
                              fontSize: 12,
                              color: "black",
                              fontWeight: "bold",
                              color:
                                selectedMake === item._id ? "white" : "black",
                            }}
                          >
                            {item.reg_number}
                          </div>
                        </div>
                      </div>
                    ))}
                  <Link to={"/addmyvehiclenew"} className="addVehicleBtn">
                    Add vehicle+{" "}
                  </Link>
                </div>
              </div>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "rgb(46,85,162)",
                }}
              >
                ₹ {total}{" "}
              </p>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginTop: 17 }}>
                  <button
                    onClick={handleAddToCart}
                    disabled={isButtonDisabled}
                    style={{
                      textDecoration: "none",
                      padding: "10px 45px",
                      borderRadius: "10px",
                      color: "white",
                      fontSize: "90%",
                      backgroundColor: isButtonDisabled ? "grey" : " #fec601",
                      fontWeight: "bold",
                      cursor: isButtonDisabled ? "not-allowed" : "pointer", // Optional: Change cursor
                    }}
                  >
                    {isLoadingBtn ? "loading... " : "ADD TO CART"}
                  </button>
                  {/* <button onClick={()=>handleUpdateVehicleData()}>
                    set to local
                  </button> */}
                </div>
              </div>
            </div>
          </div>

          <div className="descShowBox">
            <div className="descAddBox">
              <div
                onClick={showDescription}
                style={{
                  borderTop:
                    activeContent === "Content 1"
                      ? "3px solid rgb(46,85,162)"
                      : "3px solid transparent",
                  borderBottom:
                    activeContent === "Content 1"
                      ? "3px solid rgb(46,85,162)"
                      : "3px solid transparent",
                  borderLeft:
                    activeContent === "Content 1"
                      ? "1px solid rgb(46,85,162)"
                      : "1px solid transparent",
                  borderRight:
                    activeContent === "Content 1"
                      ? "1px solid rgb(46,85,162)"
                      : "1px solid transparent",
                  fontSize: 20,
                  color: "grey",
                  fontWeight: "bold",
                  textDecoration: "none",
                  padding: "0 2%",
                  cursor: "pointer",
                }}
              >
                <p className="descMainText">DESCRIPTION</p>
              </div>
              <div
                onClick={showCC}
                style={{
                  borderTop:
                    activeContent === "Content 2"
                      ? "3px solid rgb(46,85,162)"
                      : "3px solid transparent",
                  borderBottom:
                    activeContent === "Content 2"
                      ? "3px solid rgb(46,85,162)"
                      : "3px solid transparent",
                  borderLeft:
                    activeContent === "Content 2"
                      ? "1px solid rgb(46,85,162)"
                      : "1px solid transparent",
                  borderRight:
                    activeContent === "Content 2"
                      ? "1px solid rgb(46,85,162)"
                      : "1px solid transparent",
                  fontSize: 20,
                  color: "grey",
                  fontWeight: "bold",
                  textDecoration: "none",
                  padding: "0 2%",
                  cursor: "pointer",
                }}
              >
                <p className="descMainText">ADDITIONAL INFORMATION</p>
              </div>
            </div>
            {activeContent === "Content 1" ? (
              <div>
                <p className="descriptionText">Description</p>
                <p className="descText">What will you get?</p>

                {/*                 
                <div style={{ lineHeight: "2" }}>
                  {product.description &&
                    product.description.main_point && ( // Check if main_point exists
                      <div className="descText">
                        <span>
                          <img
                            src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg"
                            style={{ width: 20, height: 20 }}
                            className="rightMark"
                          />
                        </span>{" "}
                        {product.description.main_point}.
                      </div>
                    )}
                  {product.description &&
                    product.description.sub_points && // Check if sub_points exists
                    product.description.sub_points.map((point, index) => (
                      <div key={index} className="descText">
                        <span>
                          <img
                            src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg"
                            style={{ width: 20, height: 20 }}
                            className="rightMark"
                          />
                        </span>{" "}
                        {point}.
                      </div>
                    ))}
                </div> */}

                <div>
                  <div style={{ lineHeight: "2" }}>
                    {product.description &&
                      product.description.map((item, index) => (
                        <div key={index}>
                          {item.main_point && ( // Check if main_point exists
                            <div className="descText">
                              <span>
                                <img
                                  src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg"
                                  style={{ width: 20, height: 20 }}
                                  className="rightMark"
                                />
                              </span>{" "}
                              {item.main_point}
                            </div>
                          )}
                          {showSubPoints &&
                            item.sub_points &&
                            item.sub_points.length > 0 && ( // Check if sub_points exists and is not empty
                              <div style={{ marginLeft: "20px" }}>
                                {item.sub_points.map((point, subIndex) => (
                                  <div key={subIndex} className="descText">
                                    <span>
                                      <img
                                        src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg"
                                        style={{ width: 20, height: 20 }}
                                        className="rightMark"
                                      />
                                    </span>{" "}
                                    {point}.
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                      ))}
                  </div>
                  <button
                    style={{ width: "100%", backgroundColor: "grey" }}
                    onClick={() => setShowSubPoints(!showSubPoints)}
                  >
                    {showSubPoints ? "Hide Sub Points" : "Show Sub Points"}
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p className="descriptionText">Additional</p>
                <div className="ccBox">
                  <div>
                    <p style={{ fontWeight: "bold" }} className="descText">
                      CC
                    </p>
                  </div>
                  <div>
                    <p className="descText">Upto 125, Upto 200</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
