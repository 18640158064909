import React, { useEffect } from "react";
import { ServiceBook } from "../../components/ServiceBook/ServiceBook";
import { ServicePlan } from "../../components/ServicePlan/ServicePlan";
import { InfoGMI } from "../../components/InfoGMI/InfoGMI";
import { FooterHomeOne } from "../../components/FooterHome/FooterHomeOne";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { TopBar } from "../../TopBar/TopBar";

export const HomePage = () => {

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []); 

  return (
    <div style={{ backgroundColor: "#F1F0F1", maxWidth: "100%" }}>
      <TopBar />
      <ServiceBook />
      <ServicePlan />
      <InfoGMI />
      <FooterHomeOne />
      <FooterHomeSecond />
    </div>
  );
};
