import React, { useState } from "react";
import axios from "axios";
import "./RegisterLoginComponents.css";
import eyes from "../../assests/eyes.png";
import cartooneyes from "../../assests/cartoon-eyes.png";
import { useNavigate } from "react-router-dom";

export const Register = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhone_No] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleRegister = async () => {
    try {
      setIsLoading(true); // Show "uploading message"

      const userData = {
        name,
        email,
        phone_no,
        password,
      };
      const response = await axios.post(
        "https://gmi-server-1.onrender.com/api/customer/register",
        userData
      );
      setIsLoading(false);
      alert("Sign up successful");
      navigate("/login");
      signupMail()
    } catch (error) {
      console.error("Registration error:", error);
      setIsLoading(false);

      let errorMessage = "Sign up failed. Please try again.";

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = `Sign up failed: ${error.response.data.message}`;
      } else if (error.message === "Network Error") {
        errorMessage = "Network error. Please check your internet connection.";
      }

      alert(errorMessage);
    } finally {
      // Reset form fields
      setEmail("");
      setName("");
      setPhone_No("");
      setPassword("");
    }
  };

  const signupMail = async () => {
    const apiUrl =
      "https://gmi-server-1.onrender.com/api/email/user/signup-mail"; // Replace with your actual backend API URL

    const userCredentials = {
      userEmail: email,
      username: name, // Replace with the actual username
      userphone: phone_no,
    };

    try {
      const response = await axios.post(apiUrl, userCredentials);

    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handlePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };


  

  const handlePasswordChange = (text) => {
    setPassword(text);
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;

    if (text.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
    } else if (!specialCharacterRegex.test(text)) {
      setPasswordError("Password must contain at least one special character.");
    } else if (!numberRegex.test(text)) {
      setPasswordError("Password must contain at least one number.");
    } else {
      setPasswordError("");
    }
  };

  return (
    <div className="mainBox">
      <div className="Loginform">
        <label style={{ fontSize: 15 }}>Full Name</label>
        <input
          style={{ padding: "10px" }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <label style={{ fontSize: 15 }}>Email</label>
        <input
          style={{ padding: "10px" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label style={{ fontSize: 15 }}>Mobile No.</label>
                <input
                  style={{ padding: "10px" }}
                  value={phone_no}
                  onChange={(e) => setPhone_No(e.target.value)}
        />

        <label style={{ fontSize: 15 }}>Password</label>
        <div
          style={{
            display: "flex",
            border: "1px solid grey",
            justifyContent: "space-between",
            height: "35px",
          }}
        >
          <input
            style={{
              marginRight: "0px", // Add margin-right for spacing between input and button
              width: "90%",
              border: "none",
              height: "35px",
            }}
            type={isPasswordVisible ? "text" : "password"}
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
          />
          <button
            type="button"
            onClick={handlePasswordVisibility}
            style={{
              padding: "0px",
              width: "50px",
              backgroundColor: "white",
            }} // Adjust width as needed
          >
            {isPasswordVisible ? (
              <img
                src={cartooneyes}
                alt="Description of the image"
                style={{ width: "20px", height: "20px" }} // Adjust width and maxWidth as needed
              />
            ) : (
              <img
                src={eyes}
                alt="Description of the image"
                style={{ width: "20px", height: "20px" }} // Adjust width and maxWidth as needed
              />
            )}
          </button>
        </div>
        {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}

        <div>
          <p>
            Your personal data will be used to support your experience
            throughout this website, to manage access to your account, and for
            other purposes described in our privacy policy, by clicking on REGISTER you accept all our Terms and Conditions.
          </p>
          
         
          <button type="button" onClick={handleRegister} className="LoginReisterBtn">
            {isLoading ? "Updating..." : "Register"}
          </button>
        </div>
      </div>
    </div>
  );
};
