// import axios from "axios";
// import "./SingleProduct.css";
// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import bike from "../../assests/motorcycle.png";
// import bike1 from "../../assests/motorcycle1.png";

// export const TestSingleProduct = ({ id }) => {
//   const [vehicles, setVehicles] = useState([]);
//   const [total, setTotal] = useState(0);
//   const [product, setProduct] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isLoadingBtn, setIsLoadingBtn] = useState(false);
//   const [cusData, setcusData] = useState("");
//   const [citySegmentData, setCitySegmentData] = useState("");
//   const [selectedMake, setSelectedMake] = useState(null);
//   const [city, setcity] = useState("");
//   // console.log(city);

//   useEffect(() => {
//     const storedData = JSON.parse(localStorage.getItem("customer-user"));
//     setcusData(storedData);
//   }, []);

//   useEffect(() => {
//     if (cusData._id) {
//       fetchData();
//       getVehicleData();
//     }
//     setTotal(0);
//   }, [cusData._id, city]);

//   const fetchData = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.get(
//         `https://bike-server2.onrender.com/api/products/${id}`
//       );
//       setProduct(response.data);
//     } catch (err) {
//       console.log(err);
//     } finally {
//       setIsLoading(false);
//       getVehicleData();
//     }
//   };

//   const getVehicleData = async () => {
//     try {
//       if (cusData._id) {
//         const response = await axios.get(
//           `https://bike-server2.onrender.com/api/customer/${cusData._id}`
//         );
//         setVehicles(response.data.vehicles);
//       }
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   const handleSelectCity = (event) => {
//     setcity(event.target.value);
//     const selectedCity = event.target.value;

//     if (product.category === "Bike") {
//       const cityData = product[selectedCity]?.[0]?.bike_segment;

//       if (cityData && Array.isArray(cityData)) {
//         setCitySegmentData(cityData);
//       }
//     }
//   };

//   const handleSelectVehicle = (vehicle) => {
//     const ccTypeNumber = parseInt(vehicle.cc_type, 10);
//     displayCitySegmentData(ccTypeNumber);
//   };

//   const displayCitySegmentData = (selectedNumber) => {
//     const num = parseInt(selectedNumber, 10);

//     if (citySegmentData && Array.isArray(citySegmentData)) {
//       let selectedData;

//       if (num >= 90 && num <= 150) {
//         selectedData = citySegmentData[0];
//       } else if (num >= 150 && num <= 200) {
//         selectedData = citySegmentData[1];
//       } else if (num >= 200) {
//         selectedData = citySegmentData[2];
//       }

//       if (selectedData) {
//         // console.log("Selected City Segment Data:", selectedData);
//         const keys = Object.keys(selectedData);
//         const value = keys.length > 0 ? selectedData[keys[0]] : undefined;

//         setTotal(value !== undefined ? value : 0);
//       }
//     }
//   };

//   const handleAddToCart = () => {
//     setIsLoadingBtn(true);

//     console.log("Add to Cart clicked!");

//     setTimeout(() => {
//       setIsLoadingBtn(false);
//     }, 2000);
//   };

//   return (
//     <div>
//       {isLoading && (
//         <div className="spinner-container">
//           <div className="spinner"></div>
//         </div>
//       )}
//       {!isLoading && (
//         <div className="detailContainer">
//           <div className="prodDetailBox">
//             <div>
//               <img
//                 src={product.image}
//                 alt="singleProductImage"
//                 className="singleProductImg"
//               />
//             </div>
//             <div className="productRight">
//               <div>
//                 <p className="productName">{product.product_name}</p>
//               </div>

//               <div className="productNameBox">
//                 <div>
//                   <p className="productNameText">
//                     Select City <span style={{ color: "red" }}>*</span>
//                   </p>
//                 </div>
//                 <select value={city} onChange={handleSelectCity}>
//                   <option value="">Select One</option>
//                   <option value="nagpur">Nagpur</option>
//                   <option value="pune">Pune</option>
//                 </select>
//               </div>

//               <div>
//                 <p className="productNameText">
//                   Select Vehicle <span style={{ color: "red" }}>*</span>
//                 </p>
//               </div>
//               <div className="myVehicleList">
//                 {vehicles.map((item) => (
//                   <div
//                     key={item._id}
//                     onClick={() => handleSelectVehicle(item)}
//                     style={{
//                       display: "flex",
//                       flexDirection: "row",
//                       height: "100%",
//                       width: "100%",
//                       border: "1px solid #ddd",
//                       cursor: "pointer",
//                       borderRadius: 10,
//                       backgroundColor:
//                         selectedMake === item.cc_type
//                           ? "rgb(49,86,159)"
//                           : "white",
//                       color: selectedMake === item.cc_type ? "white" : "black",
//                     }}
//                   >
//                     <div
//                       style={{
//                         width: "45%",
//                         height: 70,
//                         marginRight: "10%",
//                         marginTop: "10px",
//                       }}
//                     >
//                       {selectedMake === item.cc_type ? (
//                         <img src={bike1} style={{ width: "100%" }} />
//                       ) : (
//                         <img src={bike} style={{ width: "100%" }} />
//                       )}
//                     </div>
//                     <div style={{ width: "55%", height: 60, margin: "5%  0" }}>
//                       <div
//                         style={{
//                           marginTop: "5%",
//                           fontSize: 12,
//                           color: "black",
//                           fontWeight: "bold",
//                           color:
//                             selectedMake === item.cc_type ? "white" : "black",
//                         }}
//                       >
//                         {item.model}
//                       </div>
//                       <div
//                         style={{
//                           marginTop: "15%",
//                           fontSize: 12,
//                           color: "black",
//                           fontWeight: "bold",
//                           color:
//                             selectedMake === item.cc_type ? "white" : "black",
//                         }}
//                       >
//                         {item.reg_number}
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//                 <Link to={"/addmyvehicle"} className="addVehicleBtn">
//                   Add vehicle+
//                 </Link>
//               </div>

//               <p
//                 style={{
//                   fontSize: 20,
//                   fontWeight: "bold",
//                   color: "rgb(46,85,162)",
//                 }}
//               >
//                 ₹ {total}{" "}
//               </p>

//               <div style={{ display: "flex", flexDirection: "row" }}>
//                 <div style={{ marginTop: 17 }}>
//                   <button disabled={!total || !city} onClick={handleAddToCart}>
//                     {isLoadingBtn ? "loading... " : "ADD TO CART"}
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

import React from 'react'

export const TestSingleProduct = () => {
  return (
    <div>
       TestSingleProduct
    </div>
  )
}
 