import { useEffect } from "react";
import { TopBar } from "../../TopBar/TopBar";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { SideMyAccount } from "../../components/MyAccount/SideMyAccount";
import "./MyAccount.css"

export const AddressPage = () => {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <div style={{ backgroundColor: "f0f0f0" }}>
      <TopBar />
      <div className="myAccountMainContainer">
        <SideMyAccount />
        <div style={{ width: "100%" }}>
          <p>Address</p>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
