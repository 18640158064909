import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SideMyAccount } from "../../components/MyAccount/SideMyAccount";
import { TopPage } from "../../components/TopPage/TopPage";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { TopBar } from "../../TopBar/TopBar";

export const EditAccount = () => {
  const navigation = useNavigate();
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone_no: "",
    dob: "",
    gender: "",
    address: "",
  });

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  

  useEffect(() => {
    const getDataFromStorage = async () => {
      try {
        const dataFromStorage = await localStorage.getItem("customer-user");
        if (dataFromStorage) {
          const parsedData = JSON.parse(dataFromStorage);
          setUser(parsedData);
        }
      } catch (error) {
        console.error("Error retrieving data from storage:", error);
      }
    };
    getDataFromStorage();
  }, []);

  const handleInputChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

 

  const updateProfile = async () => {
    try {
      const requiredFields = [
        "name",
        "email",
        "phone_no",
        
      ];
      const hasEmptyFields = requiredFields.some((field) => !user[field]);
      if (hasEmptyFields) {
        alert("Please fill in all required fields marked with *.");
        return;
      }

      setIsConfirmationVisible(true);
    } catch (error) {
      console.error("Failed to update profile:", error);
    }
  };

 const confirmUpdate = async () => {
   try {
     const response = await axios.put(
       `https://gmi-server-1.onrender.com/api/customer/${user._id}`,
       user
     );

     if (response.status === 200) {
       console.log("Profile updated successfully");
       setIsConfirmationVisible(false);
       navigation("/my-account");
     }
   } catch (error) {
     console.error("Failed to update profile:", error);
   }
 };

  //// profile update

  const [file, setFile] = useState(null);

const handleUpload = () => {
  if (file) {
    const data = new FormData();
    data.append("pic", file);

    axios
      .put(
        `https://gmi-server-1.onrender.com/api/customer/${user._id}/update-pic`,
        data
      )
      .then((response) => {
        console.log(response.data);
        alert("Success", "Updated profile picture");
      })
      .catch((error) => {
        console.error("Error updating profile picture:", error);
        alert("Error", "Failed to update profile picture");
      });
  }
};

   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
   
  return (
    <div style={{ backgroundColor: "f0f0f0" }}>
      <TopBar/>
      <div className="myAccountMainContainer" >
        <SideMyAccount />
        <div style={{ width: "100%", margin: "30px 0" }}>
          <div style={{ margin: "20px 0" }}>
            <label htmlFor="name">Full Name *</label>
            <input
              id="name"
              type="text"
              name="name"
              value={user.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div style={{ margin: "20px 0" }}>
            <label htmlFor="email">Email address *</label>
            <input
              id="email"
              type="email"
              name="email"
              value={user.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div style={{ margin: "20px 0" }}>
            <label htmlFor="phone_no">Mobile Number *</label>
            <input
              id="phone_no"
              type="tel"
              name="phone_no"
              value={user.phone_no}
              onChange={handleInputChange}
              required
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{width: "45%" }}>
              <label htmlFor="dob">Date of Birth </label>
              <input
                id="dob"
                type="date"
                name="dob"
                value={user.dob}
                onChange={handleInputChange}
                
              />
            </div>
            <div style={{width: "50%" }}>
              <label>Gender</label>
              <input
                id="gender"
                type="text"
                name="gender"
                value={user.gender}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div style={{ margin: "20px 0" }}>
            <label>Address</label>
            <input
              id="address"
              type="text"
              name="address"
              value={user.address}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ margin: "20px 0" }}>
            <label htmlFor="photo">Profile Photo</label>
            <div className="profileUpdateBox">
              <input className="inputProfile" type="file" onChange={(e) => setFile(e.target.files[0])} />
              <button className="uploadBtn" onClick={handleUpload}>Upload Profile Picture</button>
            </div>
          </div>
          <button
            onClick={updateProfile}
            style={{
              backgroundColor: "#FFC600",
              color: "white",
              margin: "20px 0",
              fontWeight: "bold",
              fontSize: "95%",
              width:"20%",
              marginLeft:"37%"
            }}
          >
            Save Change
          </button>
          {isConfirmationVisible && (
            <div>
              <p>Are you sure you want to update your profile?</p>
              <button onClick={confirmUpdate} style={{ margin: "20px 0",backgroundColor:"green" }}>
                Confirm
              </button>
              <button onClick={() => setIsConfirmationVisible(false)} style={{backgroundColor:"red"}}>
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
