import React, { useEffect, useState } from "react";
import "./FAQPage.css";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { TopBar } from "../../TopBar/TopBar";

export const FAQPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleParagraphClick = () => {
    setIsOpen(!isOpen);
  };

  const toggleIcon = isOpen ? <p>▼</p> : <p>▶</p>;

   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <div style={{ backgroundColor: "#F1F0F1" }}>
      <TopBar/>
      <div className="FAQBox">
        <div style={{ fontSize: 30, fontWeight: 600, textAlign: "center" }}>
          <p>Frequently Ask Questions</p>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{ display: "flex", flexDirection: "row", gap: 25 }}
            className="FAQBigText"
            onClick={handleParagraphClick}
          >
            <div style={{ cursor: "pointer" }}>
              <p>{toggleIcon}</p>
            </div>
            <div>
              <p>Why should I choose Grease Monkey?</p>
            </div>
          </div>
          <div className="FAQSmallText">
            {isOpen && (
              <p>
                Bike-ecg is now ‘Grease Monkey’, the most convenient and
                reliable on-call two-wheeler and four-wheeler Repair, Service &
                Maintenance aggregation company, promises to provide instant
                ease for vehicle troubles.
              </p>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{ display: "flex", flexDirection: "row", gap: 25 }}
            className="FAQBigText"
            onClick={handleParagraphClick}
          >
            <div style={{ cursor: "pointer" }}>
              <p>{toggleIcon}</p>
            </div>
            <div>
              <p>How do I save my time and money with Grease Monkey?</p>
            </div>
          </div>
          <div className="FAQSmallText">
            {isOpen && (
              <p>
                Greasemokey provides all its services in the most effective,
                convenient, and affordable way. We help you service your vehicle
                while you are sitting comfortably at the office or home, with a
                pickup and drop facility, moreover, we have year-round offers
                and yearly packages for our users by which they can save a huge
                amount of money on vehicle servicing.
              </p>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{ display: "flex", flexDirection: "row", gap: 25 }}
            className="FAQBigText"
            onClick={handleParagraphClick}
          >
            <div style={{ cursor: "pointer" }}>
              <p>{toggleIcon}</p>
            </div>
            <div>
              <p>In which areas are you available?</p>
            </div>
          </div>
          <div className="FAQSmallText">
            {isOpen && (
              <p>
                We are currently operating in Nagpur, Pune, and
                Pimpri-Chinchwad. Ines, Rid future, we will expand our services
                in other cities too.
              </p>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{ display: "flex", flexDirection: "row", gap: 25 }}
            className="FAQBigText"
            onClick={handleParagraphClick}
          >
            <div style={{ cursor: "pointer" }}>
              <p>{toggleIcon}</p>
            </div>
            <div>
              <p>Do you repair bikes and cars?</p>
            </div>
          </div>
          <div className="FAQSmallText">
            {isOpen && (
              <p>
                Yes, we do repair Four-wheeler and Two-wheeler vehicles both.
              </p>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{ display: "flex", flexDirection: "row", gap: 25 }}
            className="FAQBigText"
            onClick={handleParagraphClick}
          >
            <div style={{ cursor: "pointer" }}>
              <p>{toggleIcon}</p>
            </div>
            <div>
              <p>What kind of Vehicle Services are offered by Grease Monkey?</p>
            </div>
          </div>
          <div className="FAQSmallText">
            {isOpen && (
              <p>
                Grease Monkey provides the best professional Four-wheeler and
                Two-wheeler Repair, Service and Maintenance, and Interior
                Cleaning. Also Provide Pickup n Drop and Doorstep Service,
                Insurance Renewal, RTO Assistance, EV RSM, AMC (Annual
                Maintenance Contract), Towing, and Roadside Assistance.
              </p>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{ display: "flex", flexDirection: "row", gap: 25 }}
            className="FAQBigText"
            onClick={handleParagraphClick}
          >
            <div style={{ cursor: "pointer" }}>
              <p>{toggleIcon}</p>
            </div>
            <div>
              <p>Which brands of Car and Bike Grease Monkey do service?</p>
            </div>
          </div>
          <div className="FAQSmallText">
            {isOpen && (
              <p>
                We serve every vehicle brand and model. In Car – TATA, Mahindra,
                Maruti Suzuki, Hyundai, Honda, Skoda, MG, KIA, Volkswagen,
                Toyota, Ford, Nissan, Datsun, e, and All premium brands Like
                Audi, BMW, Renault Mercedes and many more. In Bike – Bajaj, Hero
                MotoCorp, Honda, Jawa, Kawasaki, KTM, Mahindra, Royal Enfield,
                Suzuki, TVS, Vespa, Yamaha, Piaggio.
              </p>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{ display: "flex", flexDirection: "row", gap: 25 }}
            className="FAQBigText"
            onClick={handleParagraphClick}
          >
            <div style={{ cursor: "pointer" }}>
              <p>{toggleIcon}</p>
            </div>
            <div>
              <p>What are your service packages for my Vehicle?</p>
            </div>
          </div>
          <div className="FAQSmallText">
            {isOpen && (
              <p>
                We have Two types of Bike Service and Three types of Car
                services, which you can choose based on the vehicle’s condition
                and needs. In Two-Wheeler: BASIC SERVICING, ADVANCE SERVICING.
                In Four-Wheeler: BASIC SERVICING, ADVANCE SERVICING, PRO
                SERVICING.
              </p>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{ display: "flex", flexDirection: "row", gap: 25 }}
            className="FAQBigText"
            onClick={handleParagraphClick}
          >
            <div style={{ cursor: "pointer" }}>
              <p>{toggleIcon}</p>
            </div>
            <div>
              <p>How to calculate the repair/service fee?</p>
            </div>
          </div>
          <div className="FAQSmallText">
            {isOpen && (
              <p>
                The cost is calculated based on the nature of the repair and the
                skills required to complete the job.
              </p>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{ display: "flex", flexDirection: "row", gap: 25 }}
            className="FAQBigText"
            onClick={handleParagraphClick}
          >
            <div style={{ cursor: "pointer" }}>
              <p>{toggleIcon}</p>
            </div>
            <div>
              <p>
                Can you tell me about Extra/Additional Repair and the
                approximate time for delivery?
              </p>
            </div>
          </div>
          <div className="FAQSmallText">
            {isOpen && (
              <p>
                In some cases, during the inspection and repair, our mechanic
                may detect additional adjustments. Charges for these services or
                replacements will add to the existing quotation. In a similar
                situation, your approval will be taken before service. However,
                if there are more repairs, we regret to inform you that the
                delivery will not be possible.
              </p>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{ display: "flex", flexDirection: "row", gap: 25 }}
            className="FAQBigText"
            onClick={handleParagraphClick}
          >
            <div style={{ cursor: "pointer" }}>
              <p>{toggleIcon}</p>
            </div>
            <div>
              <p>What payment method ov Grease Monkey offer?</p>
            </div>
          </div>
          <div className="FAQSmallText">
            {isOpen && (
              <p>
                {" "}
                We accept all payment methods like Online (Credit/Debit card,
                UPI, Payment Wallets, Net Banking), or Cash on delivery.
              </p>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{ display: "flex", flexDirection: "row", gap: 25 }}
            className="FAQBigText"
            onClick={handleParagraphClick}
          >
            <div style={{ cursor: "pointer" }}>
              <p>{toggleIcon}</p>
            </div>
            <div>
              <p>
                Do you provide Services during the weekdays pick up from office
                hours and deliver?
              </p>
            </div>
          </div>
          <div className="FAQSmallText">
            {isOpen && (
              <p>
                Yes, we provide doorstep services or pickup-drop services from
                home/office.
              </p>
            )}
          </div>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
