import React, { useEffect, useState } from "react";
import axios from "axios";
import "./myVehicles.css";
import { SideMyAccount } from "../../components/MyAccount/SideMyAccount";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { TopBar } from "../../TopBar/TopBar";
import { Link, useNavigate } from "react-router-dom";
import bike from "../../assests/bike.png";
import car from "../../assests/car.png";

export const MyVehicles = () => {
  const [vehicleData, setVehicleData] = useState([]);
  const [cusData, setCusData] = useState(null);
  console.log("vehicleData", vehicleData);

  useEffect(() => {
    const storedCustomerData = JSON.parse(
      localStorage.getItem("customer-user")
    );
    setCusData(storedCustomerData);
  }, []);

  useEffect(() => {
    if (cusData && cusData._id) {
      getVehicleData(cusData._id);
    }
  }, [cusData]);

  const getVehicleData = async (customerId) => {
    console.log("jjjjjjj", customerId);
    try {
      const response = await axios.get(
        `https://gmi-server-1.onrender.com/api/customer/${customerId}`
      );
      setVehicleData(response.data.vehicles);
    } catch (error) {
      console.error("Error fetching vehicle data:", error);
    }
  };

  const deleteData = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this vehicle?"
    );
    if (!confirmed) {
      return;
    }

    try {
      await axios.delete(
        `https://gmi-server-1.onrender.com/api/customer/${cusData._id}/vehicles/${id}`
      );
      setVehicleData(vehicleData.filter((vehicle) => vehicle._id !== id));
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  if (!cusData) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ color: "black" }}>
      <TopBar />
      <div className="myAccountMainContainer">
        <SideMyAccount />
        <div style={{ width: "100%" }}>
          <p style={{ fontSize: "150%", marginLeft: 20, fontWeight: "bold" }}>
            Vehicles Details
          </p>
          <div className="vehicles-grid">
            {vehicleData.length > 0 ? (
              vehicleData.map((item) => (
                <div className="vehicle-card" key={item._id}>
                  <ul>
                    {/* <li className="heading-text">
                      Vehicle Type: {item.vehicle_type}
                    </li> */}
                    {item.vehicle_type === "Bike" ? (
                      <div
                        style={{
                          justifyContent: "center",
                          height: "80%",
                          width: "80%",
                          margin: "auto",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={bike}
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          justifyContent: "center",
                          height: "80%",
                          width: "80%",
                          margin: "auto",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={car}
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      </div>
                    )}
                    <li className="heading-text">
                      <b> Make Model: </b>
                      {item.make_model}
                    </li>
                    <li className="heading-text">
                      <b>Variant: </b>
                      {item.variant}
                    </li>
                    <li className="heading-text">
                      <b> Fuel Type: </b> {item.fuel_type}
                    </li>
                    

                    {item.vehicle_type === "Bike" ? (
                      <li className="heading-text">
                        <b>Bike CC: </b>
                        {item.bike_cc}
                      </li>
                    ) : (
                      <li className="heading-text">
                        <b>Car Type: </b>
                        {item.car_type}
                      </li>
                    )}

                    {/* <li className="heading-text">
                      Vehicle Image: {item.vehicle_image}
                    </li> */}
                    <li className="heading-text">
                      <b>Number: </b>
                      {item.reg_number}
                    </li>
                    {/* <li className="heading-text">ID: #_{item._id.slice(-4)}</li> */}
                  </ul>
                  <button onClick={() => deleteData(item._id)}>Delete</button>
                </div>
              ))
            ) : (
              <div className="addMyvehicleNewBox">
                <p style={{ width: "100%" }}> No Vehicles added.</p>
                {/* <button>Add Vehicle</button> */}
                <Link to={"/addmyvehiclenew"} className="addMyvehicleNew">
                  Add vehicle
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
