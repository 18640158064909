import React from "react";
import { Offline, Online } from "react-detect-offline";
import ChromeDinoGame from 'react-chrome-dino';

export const MaintenancePage = () => (
  <div>
    <Online>Only shown when you're online</Online>
    <ChromeDinoGame />

    <Offline>Only shown offline (surprise!)</Offline>
  </div>
);

 
