import React from "react";
import grey from "../../assests/grey-background.png";
import fb from "../../assests/fb.png";
import tw from "../../assests/tw.png";
import yt from "../../assests/yt.png";
import ig from "../../assests/ig.png";
import ld from "../../assests/ld.png";
import { Link } from "react-router-dom";
import "./FooterHome.css";
import gmilogo from "../../assests/gmi-logo.png";


export const FooterHomeSecond = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${grey})`,
          backgroundSize: "contain",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 0px",
          height: "auto",
          maxWidth: "100%",
          paddingBottom: "3%",
        }}
      >
        <div className="secondFooterBox">
          <div className="footerLogoBox">
            <div>
              <Link to={"/"}>
                <img
                  src={gmilogo}
                  alt="logo"
                  className="logoFooter"
                />
              </Link>
            </div>
            <div className="socialBox">
              <Link to={"https://www.facebook.com/bikeecg"} target="_blank">
                <img src={fb} alt="fb" className="socialIcon" />
              </Link>
              <Link
                to={"https://x.com/bikeecg?t=gItbQF4-nXLutGUDde41og&s=09"}
                target="_blank"
              >
                <img src={tw} alt="twitter" className="socialIcon" />
              </Link>
              <Link
                to={"https://www.youtube.com/@greasemonkeyind"}
                target="_blank"
              >
                <img src={yt} alt="yt" className="socialIcon" />
              </Link>
              <Link
                to={"https://www.instagram.com/greasemonkeyind/"}
                target="_blank"
              >
                <img src={ig} alt="ig" className="socialIcon" />
              </Link>
              <Link
                to={"https://www.linkedin.com/company/bike-ecg"}
                target="_blank"
              >
                <img src={ld} alt="ld" className="socialIcon" />
              </Link>
            </div>
          </div>
          <div className="sbox">
            <div>
              <p className="bigTextFooter">Quick Links</p>
            </div>
            <Link
              to={"/terms-and-conditions"}
              style={{ textDecoration: "none" }}
            >
              <p className="smallTextFooter">Terms and Conditions</p>
            </Link>
            <Link to={"/privacy-policy"} style={{ textDecoration: "none" }}>
              <p className="smallTextFooter">Privacy Policy</p>
            </Link>
            <Link to={"/faqs"} style={{ textDecoration: "none" }}>
              <p className="smallTextFooter">FAQs</p>
            </Link>
            <Link to={"/career"} style={{ textDecoration: "none" }}>
              <p className="smallTextFooter">Careers</p>
            </Link>
            <Link to={"/about-us"} style={{ textDecoration: "none" }}>
              <p className="smallTextFooter">About Savitar Solutions Group</p>
            </Link>
          </div>
          <div className="sbox">
            <div>
              <p className="bigTextFooter">Tips, Facts and More</p>
            </div>

            <Link
              to={
                "/the-importance-of-regular-vehicle-servicing-why-you-shouldnt-skip-it"
              }
              style={{
                textDecoration: "none",
              }}
            >
              <p className="smallTextFooter">
                The importance of regular two-wheeler servicing: Why you
                shouldn’t skip it
              </p>
            </Link>
            <Link
              to={
                "/the-impact-of-seasonal-changes-on-your-vehicle-how-to-prepare-for-it"
              }
              style={{
                textDecoration: "none",
              }}
            >
              <p className="smallTextFooter">
                The impact of seasonal changes on your vehicle: how to prepare
                for it
              </p>
            </Link>
            <Link
              to={"/the-top-10-signs-that-your-vehicle-needs-servicing"}
              style={{
                textDecoration: "none",
              }}
            >
              <p className="smallTextFooter">
                The top 10 signs that your vehicle needs servicing.
              </p>
            </Link>
            <Link
              to={"/what-happens-if-i-dont-service-my-vehicle"}
              style={{
                textDecoration: "none",
              }}
            >
              <p className="smallTextFooter">
                What happens if I don’t service my bike?
              </p>
            </Link>
            <Link
              to={
                "/difference-between-local-servicing-centres-and-greasemonkey-authorised-servicing-centres"
              }
              style={{
                textDecoration: "none",
              }}
            >
              <p className="smallTextFooter">
                Difference between local servicing centres and Greasemonkey’s
                authorised servicing centres
              </p>
            </Link>
          </div>
          <div className="sbox">
            <div>
              <p className="bigTextFooter">Contact Info</p>
            </div>
            <div>
              <p className="smallTextFooter">Savitar Automotive Pvt. Ltd.</p>
            </div>
            <div style={{ width: "70%" }}>
              <p className="smallTextFooter">
                Plot No. 183, Shri Sant Gajanan Nagar, Besa, Nagpur-37, MH,
                India.
              </p>
            </div>
            <Link style={{ textDecoration: "none" }}>
              <p className="smallTextFooter">
                <span>✆</span>
                <span>+91-9960445033</span>
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="copyRightBox">
        <p className="copyRightText">
          © Copyright – Grease Monkey India – Savitar Automotive Pvt Ltd | All
          Rights Reserved | Proudly Powered with{" "}
          <span style={{ color: "red", fontSize: "120%" }}>♥</span> Savitar
          Solutions
        </p>
      </div>
    </div>
  );
};
