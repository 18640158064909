import React from "react";
import "./InfoGMI.css";
import blueIMG from "../../assests/blue-background.png";
import greenIMG from "../../assests/green-background.png";
import { Link } from "react-router-dom";
import earn from "../../assests/earn.png";
import choose1 from "../../assests/choose1.png";
import choose2 from "../../assests/choose2.png";
import choose3 from "../../assests/choose3.png";
import choose4 from "../../assests/A4.jpg";
import manfoldhand from "../../assests/manfoldhand.png";
import papercut1 from "../../assests/papercut1.png";
import papercut2 from "../../assests/papercut2.png";
import papercut3 from "../../assests/papercut3.png";
import papercut4 from "../../assests/papercut4.png";

export const InfoGMI = () => {
  return (
    <div style={{ maxWidth: "100%" }}>
      <div
        style={{
          backgroundImage: `url(${blueIMG})`,
          backgroundSize: "contain",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          height: "auto",
          maxWidth: "100%",
          marginBottom: 100,
          marginTop: 80,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="mechInfoBox">
          <div className="mechnicBox">
            <img className="mechnicSmile" src={manfoldhand} alt="mechnic" />
          </div>
          <div className="infoTextBox">
            <div>
              <h2
                className="mainText"
                style={{
                  fontSize: "160%",
                  lineHeight: 1.2,
                  color: "white",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                Want Grease Monkey to be a part of your city?
              </h2>
            </div>
            {/* <div> */}
            <p
              className="smallText"
              style={{ fontSize: "100%", color: "white", fontWeight: 500 }}
            >
              We would <span style={{ color: "red", fontSize: 20 }}>♥</span> to
              come in your city to serve you, even you can help us to find or
              connect with Garages, people you feel we can work with! Thanks for
              your help in Advance{" "}
              <span style={{ color: "red", fontSize: 15 }}>🙂</span>
            </p>
            <div className="ConnectBtn">
              <Link
                to={"/connect"}
                style={{ textDecoration: "none", color: "white" }}
              >
                Connect with us
              </Link>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className="whychooseBox">
        <div>
          <div>
            <h2 className="motoText">WHY CHOOSE US</h2>
          </div>
          <div>
            <h4 className="smallMotoText">
              NO JOB IS TOO BIG OR TOO SMALL FOR GREASE MONKEY
            </h4>
          </div>
        </div>
        <div className="chooseusBox">
          <div className="motoBox">
            <img className="chooseImg" src={choose1} alt="benefits" />
          </div>
          <div className="motoBox">
            <img className="chooseImg" src={choose2} alt="benefits" />
          </div>
          <div className="motoBox">
            <img className="chooseImg" src={choose3} alt="benefits" />
          </div>
          <div className="motoBox">
            <img className="chooseImg" src={choose4} alt="benefits" />
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${greenIMG})`,
          backgroundSize: "contain",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          height: "auto",
          width: "100%",
          // marginBottom: 100,
          // marginTop: 80,
          display: "flex",
          paddingTop: 30,
          paddingBottom: 30,
          flexDirection: "row",
          // border: '1px solid red'
        }}
      >
        <div className="PartBox">
          <img src={earn} alt="info gmi" className="infogmiImg" />
          <div className="familySection">
            {/* <div> */}
            <div className="earnWithBox">
              <h2 className="mainText">Be a Part of our Grease Monkey Family!</h2>
              {/* </div> */}
              {/* <div> */}
              <p className="smallText">
                We constantly need to expand our team – our family- to serve our
                customers and maintain serviceability. If you are a young or
                experienced enthusiast and want to be a part of transforming the
                world around us, join us! As Mobility Executive, Garage
                Owners/Mechanics, Operations/Lead/Marketing Executives.
              </p>
            </div>
            {/* </div> */}
            <div className="blackBtnbox">
              <Link to={"/earn"} className="blackBtn">
                Earn With Us ➤
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <h2 className="motoText">Recent Popular News About</h2>
        </div>
        <div className="newsBox">
          <Link
            to={
              "/the-importance-of-regular-vehicle-servicing-why-you-shouldnt-skip-it"
            }
            style={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              width: "100%",
              borderRadius: 10,
              textDecoration: "none",
              height: 260,
              backgroundColor: "white",
            }}
          >
            <div>
              <img
                src={papercut1}
                alt="gmi"
                style={{ width: "100%", borderRadius: "10px 10px 0 0" }}
              />
            </div>
            <div>
              <p className="newsTitle">Servicing Camps in Society</p>
            </div>
          </Link>
          <Link
            to={
              "/the-impact-of-seasonal-changes-on-your-vehicle-how-to-prepare-for-it"
            }
            style={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              width: "100%",
              borderRadius: 10,
              textDecoration: "none",
              backgroundColor: "white",
            }}
          >
            <div>
              <img
                src={papercut2}
                alt="gmi"
                style={{ width: "100%", borderRadius: "10px 10px 0 0" }}
              />
            </div>
            <div>
              <p className="newsTitle">Standing Up With Frontliners</p>
            </div>
          </Link>
          <Link
            to={"/the-top-10-signs-that-your-vehicle-needs-servicing"}
            style={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              width: "100%",
              borderRadius: 10,
              textDecoration: "none",
              height: 390,
              backgroundColor: "white",
            }}
          >
            <div>
              <img
                src={papercut3}
                alt="gmi"
                style={{ width: "100%", borderRadius: "10px 10px 0 0" }}
              />
            </div>
            <div>
              <p className="newsTitle">
                Featured in Newspapers – Standing Up With Frontliners
              </p>
            </div>
          </Link>
          <Link
            to={"/what-happens-if-i-dont-service-my-vehicle"}
            style={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              width: "100%",
              borderRadius: 10,
              textDecoration: "none",
              height: 260,
              backgroundColor: "white",
            }}
          >
            <div>
              <img
                src={papercut4}
                alt="gmi"
                style={{ width: "100%", borderRadius: "10px 10px 0 0" }}
              />
            </div>
            <div>
              <p className="newsTitle">YES Talks</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
