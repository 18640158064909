import React from "react";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "./Pages/HomePage/HomePage";
import { Shop } from "./Pages/Shop/Shop";
import { ProductDetails } from "./Pages/ProductDetails/ProductDetails";
import { TermAndCondition } from "./Pages/TermAndCondition/TermAndCondition";
import { PrivacyPolicy } from "./Pages/PrivacyPolicy/PrivacyPolicy";
import { FAQPage } from "./Pages/FAQPage/FAQPage";
import { RegisterLogin } from "./Pages/RegisterLogin/RegisterLogin";
import { CartPage } from "./components/CartPage/CartPage";
import { Checkout } from "./components/Checkout/Checkout";
import { OrderConfirmation } from "./components/orderConfirmation/OrderConfirmation";
import { MyAccount } from "./Pages/MyAccount/MyAccount";
import { OrderPage } from "./Pages/MyAccount/OrderPage";
// import { AddMyVehicle } from "./components/addVehicle/AddMyVehicle";
import { AddressPage } from "./Pages/MyAccount/AddressPage";
import { DownloadPage } from "./Pages/MyAccount/DownloadPage";
import { EditAccount } from "./Pages/MyAccount/EditAccount";
import { ProductCategory } from "./Pages/ProductCategory/ProductCategory";
import { TipFact1 } from "./Pages/TipFactPages/TipFact1";
import { TipFact2 } from "./Pages/TipFactPages/TipFact2";
import { TipFact3 } from "./Pages/TipFactPages/TipFact3";
import { TipFact4 } from "./Pages/TipFactPages/TipFact4";
import { TipFact5 } from "./Pages/TipFactPages/TipFact5";
import { AboutPage } from "./Pages/AboutPage/AboutPage";
import { ContactPage } from "./Pages/ContactPage/ContactPage";
import { CareerPage } from "./Pages/CareerPage/CareerPage";
import { ConnectWithUs } from "./components/InfoGMI/ConnectWithUs";
import { EarnWithUs } from "./components/InfoGMI/EarnWithUs";
import { TestSingleProduct } from "./components/SingleProduct/TestSingleProduct";
import { Test } from "./components/Test";
import { AddMyVehicleNew } from "./components/addVehicle/AddMyVehicleNew";
import { Subscription } from "./Pages/SubscriptionPage/Subscription";
import { MaintenancePage } from "./Pages/maintenancePage/MaintenancePage";
import { MyVehicles } from "./components/myVehicles/MyVehicles";

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" Component={HomePage} />
      <Route path="/shop" Component={Shop} />
      <Route path="/product/:id" Component={ProductDetails} />
      <Route path="/terms-and-conditions" Component={TermAndCondition} />
      <Route path="/privacy-policy" Component={PrivacyPolicy} />
      <Route path="/faqs" Component={FAQPage} />
      <Route path="/about-us" Component={AboutPage} />
      <Route path="/career" Component={CareerPage} />
      <Route path="/contact-us" Component={ContactPage} />
      <Route path="/login" Component={RegisterLogin} />
      <Route path="/cart" Component={CartPage} />
      <Route path="/checkout" Component={Checkout} />
      <Route path="/order-received" Component={OrderConfirmation} />
      <Route path="/my-account" Component={MyAccount} />
      <Route path="/my-account/orders" Component={OrderPage} />
      {/* <Route path="/addmyvehicle" Component={AddMyVehicle} /> */}
      <Route path="/addmyvehiclenew" Component={AddMyVehicleNew} />
      <Route path="/my-account/downloads" Component={DownloadPage} />
      <Route path="/my-account/edit-address" Component={AddressPage} />
      <Route path="/my-account/edit-account" Component={EditAccount} />
      <Route path="/productcategory/bike" Component={ProductCategory} />
      <Route path="/productcategory/car" Component={ProductCategory} />
      <Route
        path="/the-importance-of-regular-vehicle-servicing-why-you-shouldnt-skip-it"
        Component={TipFact1}
      />
      <Route
        path="/the-impact-of-seasonal-changes-on-your-vehicle-how-to-prepare-for-it"
        Component={TipFact2}
      />
      <Route
        path="/the-top-10-signs-that-your-vehicle-needs-servicing"
        Component={TipFact3}
      />
      <Route
        path="/what-happens-if-i-dont-service-my-vehicle"
        Component={TipFact4}
      />
      <Route
        path="/difference-between-local-servicing-centres-and-greasemonkey-authorised-servicing-centres"
        Component={TipFact5}
      />

      <Route path="/connect" Component={ConnectWithUs} />
      <Route path="/earn" Component={EarnWithUs} />
      <Route path="/TestSingleProduct" Component={TestSingleProduct} />
      <Route path="/Subscription" Component={Subscription} />
      <Route path="/MaintenancePage" Component={MaintenancePage} />
      <Route path="/myvehicles" Component={MyVehicles} />
      <Route path="/Test" Component={Test} />
    </Routes>
  );
};
