import React, { useEffect } from "react";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { TopPage } from "../../components/TopPage/TopPage";
import "./TipFact1.css";
import { TopBar } from "../../TopBar/TopBar";
import imgz from "../../assests/mechanic-doodle.jpg";

export const TipFact5 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ backgroundColor: "F1F0F1" }}>
      <TopBar />
      <div className="tipsBox">
        <div
          style={{
            fontSize: 30,
            fontWeight: "bold",
            color: "blacks",
            textAlign: "center",
          }}
        >
          Difference between local servicing centres and
        </div>
        <div
          style={{
            fontSize: 30,
            fontWeight: "bold",
            color: "blacks",
            textAlign: "center",
          }}
        >
          Greasemonkey’s authorised servicing centres
        </div>
        <div style={{ margin: "5% 0" }} className="tipText">
          As outlined below, there are a number of significant distinctions
          between Greasemonkey’s authorised servicing centres and local
          servicing centres.
        </div>
        <div style={{ margin: "5% 0" }}>
          <ol type="1" style={{ lineHeight: 1.5 }}>
            <li className="tipText">
              Expertise and Experience: Greasemonkey is a specialized vehicle
              service company with a staff of knowledgeable technicians who are
              trained and certified to service a variety of vehicle makes and
              models. Local repair shops might not always have the same degree
              of skill and experience because they might work on several kinds
              of vehicles without having a specific background in vehicle
              maintenance or training.
            </li>
            <li className="tipText">
              Service Quality: Greasemonkey is renowned for offering top-notch
              servicing while upholding industry standards and best practices.
              To ensure complete and dependable servicing, they use original
              parts and cutting-edge tools and equipment. Local service
              providers might not always adhere to the same standards or utilize
              genuine components, which could lower the caliber of the work
              done.
            </li>
            <li className="tipText">
              Convenience and dependability: Greasemonkey provides convenience
              through its mobile vehicle servicing approach, wherein its
              professionals service your vehicle on-site, saving you time and
              travel. It can be necessary for you to visit nearby maintenance
              centres in person, which isn’t always convenient. With a
              transparent price structure and warranty on their services,
              Greasemonkey also offers a dependable and consistent level of
              customer care.
            </li>
            <li className="tipText">
              Services Offered: Regular maintenance, repairs, part replacements,
              and specialized services are all part of the wide range of vehicle
              servicing and maintenance services that Greasemonkey provides.
              Local servicing facilities could have restrictions on the services
              they provide or the level of knowledge they have.
            </li>
            <li className="tipText">
              Trust and Reputation: Greasemonkey has a solid reputation as a
              reliable provider of vehicle services, as seen by its many
              favourable client testimonials. They prioritize customer happiness
              and use a professional approach. Local servicing facilities might
              not always have the same amount of credibility and goodwill, and
              the level of service might differ.
            </li>
            <li className="tipText">
              Specialized Solutions Greasemonkey offers specialized solutions
              based on the make, model, age, usage, and demands of your vehicle.
              To meet your requirements and price range, they provide customized
              servicing programs. It’s possible that local servicing centres
              won’t always offer the same level of personalisation or
              modification.
            </li>
          </ol>
        </div>
        <div className="tipText">
          In conclusion, Greasemonkey servicing centres provide knowledge,
          quality, convenience, dependability, a wide range of services, trust,
          and tailored solutions that may differ from those provided by local
          maintenance centres. Many vehicle owners seek dependable and
          high-quality repair for their vehicles like Greasemonkey due to their
          professional approach and specialized skills in vehicle servicing.
        </div>
        <div style={{ margin: "5% 0" }}>
          <div className="tipCardText">YOU MIGHT ALSO LIKE</div>
          <div className="tipmotoMainBox">
            <div className="tipMotoBox">
              <img className="tipImg" src={imgz} />
              <div className="tipCardText">
                The top 10 signs that your vehicle needs servicing.
              </div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
            <div className="tipMotoBox">
              <img className="tipImg" src={imgz} />
              <div className="tipCardText">
                What happens if I don’t service my vehicle?
              </div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
            <div className="tipMotoBox">
              <img className="tipImg" src={imgz} />
              <div className="tipCardText">
                The benefits of regular oil changes for your vehicle: why you
                shouldn’t delay
              </div>
              <div className="tipCardSmallText">⌚ May 10, 2023</div>
            </div>
          </div>
          <div
            style={{
              width: "40%",
              margin: "2% auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ margin: "2% 0" }} className="tipCardText">
              Leave a Reply
            </div>
            <textarea
              style={{
                border: "1px solid black",
                width: "100%",
                height: 100,
                margin: "5% 0",
              }}
            />
            <button style={{ width: "50%", backgroundColor: "#FFC701" }}>
              Post Comment
            </button>
          </div>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
