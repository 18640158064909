import React, { useState } from "react";
import "./connectwithus.css";
import { TopBar } from "../../TopBar/TopBar";
import { FooterHomeSecond } from "../FooterHome/FooterHomeSecond";
import axios from "axios";

// Functional component for the business form
export const ConnectWithUs = () => {
  // State variables to store form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile_no: "",
    address: "",
  });

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://gmi-server-1.onrender.com/contactus/create",
        formData
      );
      console.log("Contact us success:", response.data);
      alert("Contact upload successfully:");
    } catch (err) {
      console.error("Error submitting form:", err);
    }
    console.log("Form data submitted:", formData);
    // Clear the form after submission
    setFormData({
      name: "",
      email: "",
      mobile_no: "",
      address: "",
    });
  };

  return (
    <div>
      <TopBar />
      <div className="connectBox">
        <h2 className="connectheading">Contact Us</h2>
        <form className="connectform" onSubmit={handleSubmit}>
          <label className="connectlabel" htmlFor="name">
            Name:
          </label>
          <input
            type="text"
            className="connectinput"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />

          <label className="connectlabel" htmlFor="email">
            Email:
          </label>
          <input
            type="email"
            className="connectinput"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />

          <label className="connectlabel" htmlFor="mobile_no">
            Mobile No.:
          </label>
          <input
            type="number"
            className="connectinput"
            name="mobile_no"
            value={formData.mobile_no}
            onChange={handleInputChange}
            required
          />

          <label className="connectlabel" htmlFor="address">
            Full Address:
          </label>
          <textarea
            className="connecttextarea"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            required
          ></textarea>

          <button className="connectbutton" type="submit">
            Submit
          </button>
        </form>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
