import axios from "axios";
import React, { useEffect, useState } from "react";
import "./AllProducts.css";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

export const AllProducts = ({ category }) => {
  const [mycategory, setCategory] = useState(category);
  // console.log("category:::::", category);
  const navigate = useNavigate();
  const [plan, setPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const optionsCity = [
    { value: "pune", label: "Pune" },
    { value: "nagpur", label: "Nagpur" },
  ];
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  console.log("selectedVehicleType:", selectedVehicleType);
  const options = [
    { value: "Bike", label: "➭ Bike Services" },
    { value: "Car", label: "➭ Car Services" },
    { value: "Breakdown", label: "➭ Breakdown Services" },
    { value: "Bike Yearly", label: "➭ Yearly Bike Services" },
  ];

  var [customerIdData, setCustomerIdData] = useState("");

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("customer-user"));
    setCustomerIdData(storedData);
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://gmi-server-1.onrender.com/api/products/allproducts"
      );
      setPlan(response.data);
    } catch (err) {
      console.log(err);
      setIsLoading(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleVehicleTypeChange = (selectedOption) => {
    setSelectedVehicleType(selectedOption.value);
  };

  const clearFilter = () => {
    setSelectedVehicleType(null);
    setCategory(undefined);
  };

  // const filteredProducts = plan.filter((product) => {
  //   if (selectedVehicleType === null) {
  //     return true;
  //   }
  //   return product.category === selectedVehicleType;
  // });
  const filteredProducts = plan.filter((product) => {
    if (selectedVehicleType === null && mycategory === undefined) {
      return true;
    }
    if (selectedVehicleType !== null) {
      return product.category === selectedVehicleType;
    }
    if (mycategory !== undefined) {
      return product.category === mycategory;
    }

    return false;
  });

  console.log("filteredProducts>>>>", filteredProducts);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (item) => {
    navigate(`/product/${item._id}`, { state: { item } });
  };

  return (
    <div className="shopMainBox">
      <div style={{ width: "25%" }} className="filterContainer">
        {/* <div style={{ margin: 40 }}>
          <div>
            <p className="bigTextProductPage">Select your City</p>
          </div>
          <Select
            className="selectOption"
            options={optionsCity}
            placeholder={"Select City"}
          />
          <div>
            {" "}
            <p
              style={{
                fontSize: 15,
                color: "black",
                fontWeight: 400,
                cursor: "pointer",
              }}
              onClick={clearFilter}
            >
              clear❌
            </p>
          </div>
        </div> */}

        <div style={{ margin: 40, marginTop: "20%" }}>
          <div>
            <p className="bigTextProductPage">Sort Services:</p>
          </div>
          <Select
            className="selectOption"
            options={options}
            placeholder="Select Service"
            value={
              selectedVehicleType
                ? options.find((option) => option.value === selectedVehicleType)
                : null
            }
            onChange={handleVehicleTypeChange}
          />
          {selectedVehicleType && (
            <div style={{ margin: "5px 40px 5px 0px" }}>
              <p className="cancleBtn" onClick={clearFilter}>
                Clear❌
              </p>
            </div>
          )}
        </div>

        {/* <div style={{ margin: 40 }}>
          <div>
            <p className="bigTextProductPage">Car Segment</p>
          </div>
          <div style={{ lineHeight: 2.5, marginTop: -10 }}>
            <div style={{ display: "flex", flexDirection: "row", height: 30 }}>
              <input type="checkbox" className="checkBoxCC" />
              <label style={{ fontSize: 15, color: "black", fontWeight: 400 }}>
                Hatchback
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "row", height: 30 }}>
              <input type="checkbox" className="checkBoxCC" />
              <label style={{ fontSize: 15, color: "black", fontWeight: 400 }}>
                Seden
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "row", height: 30 }}>
              <input type="checkbox" className="checkBoxCC" />
              <label style={{ fontSize: 15, color: "black", fontWeight: 400 }}>
                SUV
              </label>
            </div>
          </div>
        </div>
        <div style={{ margin: 40 }}>
          <div>
            <p className="bigTextProductPage">Bike CC</p>
          </div>
          <div style={{ lineHeight: 2.5, marginTop: -10 }}>
            <div style={{ display: "flex", flexDirection: "row", height: 30 }}>
              <input type="checkbox" className="checkBoxCC" />
              <label style={{ fontSize: 15, color: "black", fontWeight: 400 }}>
                Upto 125
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "row", height: 30 }}>
              <input type="checkbox" className="checkBoxCC" />
              <label style={{ fontSize: 15, color: "black", fontWeight: 400 }}>
                Upto 150
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "row", height: 30 }}>
              <input type="checkbox" className="checkBoxCC" />
              <label style={{ fontSize: 15, color: "black", fontWeight: 400 }}>
                Upto 220
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "row", height: 30 }}>
              <input type="checkbox" className="checkBoxCC" />
              <label style={{ fontSize: 15, color: "black", fontWeight: 400 }}>
                Upto 400
              </label>
            </div>
          </div>
        </div> */}
      </div>
      <div style={{ width: "75%", marginTop: 50, marginBottom: 50 }}>
        {isLoading && (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        )}
        {!isLoading && (
          <div className="shopContainer">
            {filteredProducts.map((item) => (
              <div
                key={item._id}
                style={{
                  textDecoration: "none",
                  //   marginBottom: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "2%",
                  borderRadius: "15px",
                  backgroundColor: "white",
                }}
              >
                <div style={{ textAlign: "center", padding: "5%" }}>
                  {/* <img
                    src={item.image}
                    alt="product item"
                    style={{ width: "100%", height: "100%" }}
                  /> */}
                  <img
                    src={require(`../../assests/${item.image}` )}
                    alt="product item"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: 15,
                    fontWeight: 400,
                    color: "grey",
                    textAlign: "center",
                    margin: 7,
                  }}
                >
                  {item.category}
                </div>
                <div
                  style={{
                    fontSize: 18,
                    height: "15%",
                    fontWeight: "bold",
                    color: "black",
                    textAlign: "center",
                    margin: 7,
                  }}
                >
                  {item.product_name}
                </div>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#6660B6",
                    textAlign: "center",
                    margin: 7,
                  }}
                >
                  {item.price_tag}
                </div>
                <div style={{ bottom: 0, margin: 7 }} className="selectBtn">
                  {customerIdData ? (
                    <div>
                      <button
                        onClick={() => handleClick(item)}
                        // to={`/product/${item._id}`}
                        style={{
                          textDecoration: "none",
                          backgroundColor: "#FCC701",
                          fontSize: 15,
                          color: "white",
                          fontWeight: 500,
                          paddingLeft: 5,
                          display: "inline-block",
                        }}
                      >
                        Select Options
                      </button>
                    </div>
                  ) : (
                    <div>
                      <Link
                        to="/login"
                        style={{
                          textDecoration: "none",
                          backgroundColor: "#FCC701",
                          fontSize: 12,
                          color: "white",
                          fontWeight: 700,
                        }}
                      >
                        Login to View Details
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
