import React, { useEffect, useState } from "react";
import "./SideMyAccount.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// import protection from "../../assets/protection.png";
import addVehicle from "../../assests/addVehicle.png";

export const SideMyAccount = () => {
  const navigation = useNavigate();
  const [customerIdData, setCustomerIdData] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [image, setImage] = useState("");

  const fetchCustomerData = () => {
    axios
      .get(
        `https://gmi-server-1.onrender.com/api/customer/${customerIdData._id}`
      )
      .then((response) => {
        const data = response.data;
        setCustomerData(data);
        setImage(data.pic);
      })
      .catch((error) => {
        console.error("Failed to fetch customer data:", error);
      });
  };

  const getDataFromStorage = async () => {
    try {
      const dataFromStorage = await localStorage.getItem("customer-user");
      if (dataFromStorage !== null) {
        const parsedData = JSON.parse(dataFromStorage);
        setCustomerIdData(parsedData);
      }
    } catch (error) {
      console.log("Error retrieving data from storage:", error);
    }
  };

  useEffect(() => {
    getDataFromStorage();
  }, []);

  useEffect(() => {
    if (customerIdData && customerIdData._id) {
      fetchCustomerData();
    }
  }, [customerIdData]);

  const handleLogout = (e) => {
    e.preventDefault();
    // Confirmation dialog
    if (window.confirm("Are you sure you want to log out?")) {
      localStorage.removeItem("customer-user");
      navigation("/login");
    }
  };

  const name = customerData?.name || "";

  return (
    <div className="sidebar">
      <div
        style={{
          borderBottom: "0.5px solid grey",
          width: "90%",
          display: "flex",
          flexDirection: "row",
          gap: 10,
        }}
      >
        <div style={{ color: "black" }}>
          {customerData && image && (
            <img
              src={`https://gmi-server-1.onrender.com/${image
                .replace("public", "")
                .replace(/\\/g, "/")}`}
              alt="👽"
              style={{ width: 70, height: 70, borderRadius: "50%" }}
            />
          )}
        </div>
        <div>
          <div>
            <p>{name.charAt(0).toUpperCase() + name.slice(1)}</p>
          </div>
        </div>
      </div>
      <div className="sidebar-menu">
        <Link to={"/my-account/edit-account"} className="btnLink">
          <p className="myAccountText">My Profile</p>
          <img
            style={{ width: 25, height: 25, marginTop: 20 }}
            src="https://cdn-icons-png.flaticon.com/128/266/266134.png"
            alt="details"
          />
        </Link>
        <Link to={"/my-account/orders"} className="btnLink">
          <p className="myAccountText">Orders</p>
          <img
            style={{ width: 25, height: 25, marginTop: 20 }}
            src="https://cdn-icons-png.flaticon.com/128/1170/1170678.png"
            alt="order"
          />
        </Link>
        <Link to={"/my-account/downloads"} className="btnLink">
          <p className="myAccountText">Billing Details</p>
          <img
            style={{ width: 25, height: 25, marginTop: 20 }}
            src="https://cdn-icons-png.flaticon.com/128/860/860802.png"
            alt="download"
          />
        </Link>

        <Link to={"/myvehicles"} className="btnLink">
          <p className="myAccountText">My Vehicles</p>
          <img
            style={{ width: 30, height: 30, marginTop: 15, marginLeft: 30 }}
            src={addVehicle}
            alt="download"
          />
        </Link>

        <Link className="btnLink" onClick={handleLogout}>
          <p className="myAccountText">Log out</p>
          <img
            style={{ width: 25, height: 25, marginTop: 20 }}
            src="https://cdn-icons-png.flaticon.com/128/9070/9070510.png"
            alt="logout"
          />
        </Link>
      </div>
    </div>
  );
};
