import React, { useState } from "react";
import axios from "axios";
import "./RegisterLoginComponents.css";
import eyes from "../../assests/eyes.png";
import cartooneyes from "../../assests/cartoon-eyes.png";
import { useNavigate } from "react-router-dom";

export const Login = () => {
   const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");

  const handleSignin = async () => {
    const userData = {
      email,
      password,
    };
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://gmi-server-1.onrender.com/api/customer/login",
        userData
      );
      setIsLoading(false);
      if (response.status === 200) {
        setEmail("");
        setPassword("");

        localStorage.setItem("customer-user", JSON.stringify(response.data));
        alert("Welcome back! You have successfully logged in.");
        setLoginMessage("Login successful");
         navigate("/");
      } else {
        console.log("Authentication failed");
      }

    } catch (err) {
      console.log(err);
      setIsLoading(false);

      alert(
        "Oops! Something went wrong. Please check your credentials and try again."
      );
      setLoginMessage("Login failed. Please try again.");
    }
  };

  const handlePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const handlePasswordChange = (text) => {
    setPassword(text);
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;

    if (text.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
    } else if (!specialCharacterRegex.test(text)) {
      setPasswordError("Password must contain at least one special character.");
    } else if (!numberRegex.test(text)) {
      setPasswordError("Password must contain at least one number.");
    } else {
      setPasswordError("");
    }
  };




  return (
    <div className="mainBox">
      <div className="Loginform">
        <label style={{ fontSize: 15 }}>Email:</label>
        <input
          style={{ padding: "10px", border: "1px solid grey" }}
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label style={{ fontSize: 15 }}>Password:</label>
        <div
          style={{
            display: "flex",
            border: "1px solid grey",
            justifyContent: "space-between",
            height: "35px",
          }}
        >
          <input
            style={{
              marginRight: "1px", // Add margin-right for spacing between input and button
              width: "90%",
              border: "none",
              height: "35px",
            }}
            type={isPasswordVisible ? "text" : "password"}
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
          />
          <button
            type="button"
            onClick={handlePasswordVisibility}
            style={{ padding: "0px", width: "50px", backgroundColor: "white" }} // Adjust width as needed
          >
            {isPasswordVisible ? (
              <img
                src={cartooneyes}
                alt="Description of the image"
                style={{ width: "20px", height: "20px" }} // Adjust width and maxWidth as needed
              />
            ) : (
              <img
                src={eyes}
                alt="Description of the image"
                style={{ width: "20px", height: "20px" }} // Adjust width and maxWidth as needed
              />
            )}
          </button>
        </div>

        {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}

        <button
          style={{
            marginTop: "5%",
          }}
          type="button"
          onClick={handleSignin}
          disabled={isLoading}
          className="LoginReisterBtn"
        >
          {isLoading ? "Updating..." : "Login"}
        </button>

      </div>
    </div>
  );
};
