import React from 'react'
import './subscription.css'

export const Subscription = () => {
  return (
    <div>
      <h1 className='topheading'>Comming soon...</h1>
    </div>
  );
}

 