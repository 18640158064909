import React, { useEffect, useState } from "react";
import { TopPage } from "../TopPage/TopPage";
import { FooterHomeSecond } from "../FooterHome/FooterHomeSecond";
import "./cartpage.css";
import axios from "axios";
import cancel from "../../assests/cancel.png";
import { Link, useNavigate } from "react-router-dom";
import { TopBar } from "../../TopBar/TopBar";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

export const CartPage = () => {
  let navigate = useNavigate();
  const [products, setProducts] = useState([]);
  // console.log("products>>>>",products)
  const [cusData, setcusData] = useState("");

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("customer-user"));
    setcusData(storedData);
  }, []);

  const fetchData = async () => {
    try {
      if (cusData) {
        const response = await axios.get(
          `https://gmi-server-1.onrender.com/api/customer/${cusData._id}`
        );

        if (response.status !== 200) {
          throw new Error("Failed to fetch data");
        }

        // Assuming your data is an array or object within the response
        setProducts(response.data.mycart);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    if (cusData) {
      fetchData();
    }
  }, [cusData]);

  const handleDelete = async (id) => {
    try {
      if (cusData) {
        await axios.delete(
          `https://gmi-server-1.onrender.com/api/customer/${cusData._id}/cart/${id}`
        );

        fetchData();
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const calculateSubTotal = () => {
    return products.reduce((total, item) => total + Number(item.total), 0);
  };

  const finalsubTotal = calculateSubTotal();

  //////////////////////////////////////////////////////

  const [couponCode, setCouponCode] = useState("");
  // console.log("couponCode:", couponCode);

  const [discount, setDiscount] = useState(0);

  // Calculate the total amount
  const calculateTotal = () => {
    const discountedTotal = finalsubTotal - discount;
    // const discountedTotal = finalsubTotal - (finalsubTotal * discount) / 100;   //% discount
    return discountedTotal;
  };

  // Handle coupon code input
  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  // Apply coupon amount
  // const applyCoupon = () => {
  //   setDiscount(couponCode);
  // };
  const [couponStatus, setCouponStatus] = useState("")
  const applyCoupon = () => {
    // Find the coupon with the matching name
    const matchedCoupon = coupons.find(
      (coupon) => coupon.coupons_name === couponCode
    );

    // If a matching coupon is found, set the discount to its amount
    if (matchedCoupon) {
      setDiscount(matchedCoupon.amount);
      setCouponStatus("Coupon is Applied")
    } else {
      setCouponStatus("Coupon is Not Applied");
      console.log("Coupon not found");
    }
  };


  ////////////////////////////
  const [coupons, setCoupons] = useState([]);
//  console.log("coupons:", coupons);

  useEffect(() => {
    const getCoupons = async () => {
      try {
        const response = await axios.get(
          "https://gmi-server-1.onrender.com/api/coupons/allget"
        );

        if (response.status !== 200) {
          throw new Error("Failed to fetch data");
        }

        setCoupons(response.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    getCoupons();
  }, []);

  ///
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const handleCouponClick = (couponCode) => {
    setSelectedCoupon(couponCode);
  };
  ////

  const handleCheckout = () => {
    const finalTotal = calculateTotal();

    navigate(
      `/checkout?products=${encodeURIComponent(
        JSON.stringify(products)
      )}&finalsubTotal=${finalsubTotal}&finalTotal=${finalTotal}&discount=${discount}`
    );

    const matchedCoupon = coupons.find(
      (coupon) => coupon.coupons_name === couponCode
    );
    console.log(matchedCoupon._id)

    deleteCoupon(matchedCoupon._id);
  };
  
  const deleteCoupon = async (couponId) => {
    try{
      const response = await axios.delete(
        `https://gmi-server-1.onrender.com/api/coupons/delete/${couponId}`
      );
      if (response.status === 200){
        console.log(response.data.message)
      }else{
        throw new Error(response.data.message || "Failed to delete coupon");
      }
    }catch(err){console.log(err.message)}
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: "#F1F0F1" }}>
      <TopBar />
      <div style={{ marginTop: 40 }} className="tableCartBox">
        <div>
          {products.length > 1 && (
            <p style={{ color: "red", fontSize: "90%" }}>
              ( Note: Please add only one product to the cart at a time. )
            </p>
          )}

          <Table>
            <Thead>
              <Tr
                style={{
                  backgroundColor: "white",
                  height: "50px",
                }}
              >
                <Th
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    paddingLeft: "2%",
                    textAlign: "left",
                  }}
                >
                  REMOVE
                </Th>
                <Th
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    paddingLeft: "2%",
                    textAlign: "left",
                  }}
                >
                  IMAGE
                </Th>
                <Th
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    paddingLeft: "2%",
                    textAlign: "left",
                  }}
                >
                  PRODUCT
                </Th>
                <Th
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "left",
                    paddingLeft: "2%",
                  }}
                >
                  PRICE
                </Th>
                <Th
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "left",
                    paddingLeft: "2%",
                  }}
                >
                  SUBTOTAL
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {products.map((product, index) => (
                <Tr key={product._id + index}>
                  <Td
                    style={{
                      border: "0.1px solid grey",
                      paddingTop: 15,
                      paddingBottom: 15,
                    }}
                    onClick={() => {
                      handleDelete(product._id);
                    }}
                  >
                    <img src={cancel} alt="Cancel" className="cancleimg" />
                  </Td>
                  <Td
                    style={{
                      border: "0.1px solid grey",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <Link to={`/product/${product._id}`}>
                      <img
                        src={
                          product.image
                            ? require(`../../assests/${product.image}`)
                            : "Loading Image..."
                        }
                        alt={`Service Plan: ${product.product_name}`}
                        className="cartImg"
                      />
                    </Link>
                  </Td>
                  <Td
                    style={{
                      fontSize: 20,
                      fontWeight: "normal",
                      paddingLeft: "2%",
                      textAlign: "left",
                      border: "0.1px solid grey",
                      paddingTop: 15,
                      paddingBottom: 15,
                    }}
                  >
                    {product.product_name}
                  </Td>
                  <Td
                    style={{
                      fontSize: 20,
                      fontWeight: "normal",
                      paddingLeft: "2%",
                      textAlign: "left",
                      color: "rgb(46,85,162)",
                      border: "0.1px solid grey",
                      paddingTop: 15,
                      paddingBottom: 15,
                    }}
                  >
                    {product.price_tag}
                  </Td>
                  <Td
                    style={{
                      fontSize: 20,
                      fontWeight: "normal",
                      paddingLeft: "2%",
                      textAlign: "left",
                      color: "rgb(46,85,162)",
                      border: "0.1px solid grey",
                      paddingTop: 15,
                      paddingBottom: 15,
                    }}
                  >
                    ₹ {product.total}.
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
        <div
          style={{
            width: "100%",
            margin: "auto",
            marginTop: 50,
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "40%" }}>
            <div>
              <input
                style={{
                  width: "45%",
                  border: "0.5px solid rgb(221,221,221)",
                  margin: "1% 2%",
                  borderRadius: "10px",
                }}
                placeholder="Coupon code"
                value={couponCode}
                onChange={handleCouponCodeChange}
              />
              <button className="applyBtn" onClick={applyCoupon}>
                APPLY COUPON
              </button>
            </div>
            <p
              style={{
                textAlign:'center',
                color: couponStatus === "Coupon is Applied" ? "green" : "red",
              }}
            >
              {couponStatus}
            </p>

            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                {coupons.map((coupon) => (
                  <div
                    key={coupon._id}
                    onClick={() => {
                      handleCouponClick(coupon.amount);
                      setCouponCode(coupon.amount);
                    }}
                    style={{
                      border: "none",
                      width: "80%",
                      backgroundColor:
                        selectedCoupon === coupon.amount
                          ? "lightblue"
                          : "transparent",
                      padding: "5px",
                      margin: "3px",
                      cursor: "pointer",
                      display: "inline-block",
                      borderRadius: "10px",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <strong>{coupon.coupons_name}</strong> get ₹
                    <strong>{coupon.amount}</strong>/- off
                  </div>
                ))}
              </div>
            </div> */}
          </div>

          <div
            style={{
              width: "54%",
            }}
          >
            <p
              style={{
                textAlign: "center",
                backgroundColor: "white",
                padding: "1%",
                fontSize: "90%",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
            >
              CART TOTALS
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: -15,
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  padding: "0 5%",
                  width: "30%",
                }}
              >
                Subtotal
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  color: "rgb(46,85,162)",
                  marginLeft: 20,
                }}
              >
                ₹ {calculateSubTotal()}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: -15,
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  padding: "0 5%",
                  width: "30%",
                }}
              >
                Discount
              </p>
              <p
                style={{
                  fontSize: 18,
                  color: "red",
                  marginLeft: 20,
                  marginTop: 22,
                }}
              >
                - ₹ {discount}{" "}
                <span
                  style={{
                    fontSize: 15,
                    color: "grey",
                  }}
                >
                  (By Applied Coupon)
                </span>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: -15,
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 30,
                  padding: "0 5%",
                  width: "30%",
                }}
              >
                Total
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 30,
                  color: "rgb(46,85,162)",
                  marginLeft: 20,
                }}
              >
                ₹ {Math.floor(calculateTotal())}/-
              </p>
            </div>
            <div style={{ marginTop: 10 }}>
              <button
                className="checkoutbtn"
                onClick={handleCheckout}
                disabled={products.length === 0}
                style={{
                  cursor: products.length === 0 ? "not-allowed" : "pointer",
                }}
              >
                PROCEED TO CHECKOUT
              </button>
            </div>
          </div>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
