import React, { useEffect } from 'react';
import { FooterHomeSecond } from '../../components/FooterHome/FooterHomeSecond';
import { TopBar } from '../../TopBar/TopBar';
import "./CareerPage.css"


export const CareerPage = () => {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <div style={{backgroundColor:"F1F0F1"}}>
        <TopBar/>
        <div className='careerBox'>
          <p>Career is Opening Soon</p>
        </div>
        <FooterHomeSecond/>
    </div>
  );
}
