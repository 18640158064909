import React, { useEffect, useState } from "react";
import "./FooterHome.css";
import grey from "../../assests/grey-background.png";
import skyscraper from "../../assests/skyscraper.png";
import hatchback from "../../assests/hatchback.png";
import kkkk from "../../assests/kkkk.png";
import customersatisfaction from "../../assests/customersatisfaction.png";


export const FooterHomeOne = () => {
  const [countCity, setCountCity] = useState(0);
  const [countVehicle, setCountVehicle] = useState(0);
  const [countInsurance, setCountInsurance] = useState(0);
  const [countClient, setCountClient] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (countCity < 2) {
        setCountCity(countCity + 1);
      }
      if (countVehicle < 3000) {
        setCountVehicle(countVehicle + 1);
      }
      if (countInsurance < 500) {
        setCountInsurance(countInsurance + 1);
      }
      if (countClient < 2500) {
        setCountClient(countClient + 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1);

    return () => clearInterval(intervalId);
  }, [countCity, countVehicle, countInsurance, countClient]);
  return (
    <div
      style={{
        backgroundImage: `url(${grey})`,
        backgroundSize: "contain",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        height: "auto",
        width: "100%",
        marginTop: 80,
        paddingBottom: 21,
        display: "flex",
        flexDirection: "column", // To stack children vertically
        alignItems: "center", // Center horizontally
        justifyContent: "center", // Center vertically
      }}
    >
      <div className="clientMainBox">
        <div className="clietItem">
          <div>
            <img src={skyscraper} alt="skyscraper" className="footerImg" />
          </div>
          <div>
            <div style={{ marginTop: 80 }}>
              <p className="number">{countCity}</p>
            </div>
            <div style={{ marginTop: -20 }}>
              <p className="yellowText">Cities Serving</p>
            </div>
          </div>
        </div>
        <div className="clietItem">
          <div>
            <img src={hatchback} alt="vehicle" className="footerImg" />
          </div>
          <div>
            <div style={{ marginTop: 80 }}>
              <p className="number">{countVehicle}+</p>
            </div>
            <div style={{ marginTop: -20 }}>
              <p className="yellowText">Vehicle Serviced</p>
            </div>
          </div>
        </div>
        <div className="clietItem">
          <div>
            <img src={kkkk} alt="insurance" className="footerImg" />
          </div>
          <div>
            <div style={{ marginTop: 80 }}>
              <p className="number">{countInsurance}+</p>
            </div>
            <div style={{ marginTop: -20 }}>
              <p className="yellowText">Insurance Renewals</p>
            </div>
          </div>
        </div>
        <div className="clietItem">
          <div>
            <img
              src={customersatisfaction}
              alt="clients"
              className="footerImg"
            />
          </div>
          <div>
            <div style={{ marginTop: 80 }}>
              <p className="number">{countClient}+</p>
            </div>
            <div style={{ marginTop: -20 }}>
              <p className="yellowText">Happy Clients</p>
            </div>
          </div>
        </div>
      </div>
      <div className="textBox">
        <div className="textInnerBox">
          <div>
            <p className="whiteText">
              Grease Monkey, the most convenient and reliable on-call Vehicle
              Repair, Service & Maintenance aggregation company, promises to
              provide instant ease for your vehicle troubles.
            </p>
          </div>
          <div>
            <p className="whiteText">
              We believe that we are intuitive yet efficient with our work to
              create technological transformation in vehicle RSM (Repair,
              Service and Maintenance). With our well-equipped and quality
              checked Garages and disciplined workforce, we aspire to provide
              you with the ease of Services like Vehicle Servicing, Repairing,
              Insurance Renewals, RTO Assistance, EV RSM, Annual Maintenance
              Contracts, Towing, and Roadside assistance.
            </p>
          </div>
          <div>
            <p className="whiteText">
              Grease Monkey always goes with the market pace which makes us
              strive to be the best amongst the rest, with technological
              advantages and integration we would transform the way India
              Services, Repairs and Maintains their Vehicles.
            </p>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};
